import HttpService from "./http.service";
import { BACK_END_URL } from "../environments";
import DiseaseTransform from "./disease.transform";

export default class DiseaseService {
  constructor() {
    this.http = new HttpService();
    this.transform = new DiseaseTransform();
  }

  filterDiseases(filters) {
    return this.http
      .post(`${BACK_END_URL}/filtering_page.php`, filters)
      .then((res) => this.transform.filteringDisease().success(res));
  }

  getDiseaseDetail(data) {
    return this.http
      .post(`${BACK_END_URL}/detail_page.php`, data)
      .then((res) => this.transform.getDiseaseDetail().success(res));
  }

  getDiseaseList(data) {
    return this.http
      .post(`${BACK_END_URL}/disease_list.php`, data)
      .then((res) => this.transform.getDiseaseList().success(res));
  }

  insertDisease(data) {
    return this.http.post(`${BACK_END_URL}/insert_disease.php`, data);
  }

  updateDisease(data) {
    return this.http.post(`${BACK_END_URL}/update_disease.php`, data);
  }

  insertSignificance(data) {
    return this.http.post(`${BACK_END_URL}/insert_significance.php`, data);
  }

  updateSignificance(data) {
    return this.http.post(`${BACK_END_URL}/update_significance.php`, data);
  }

  cloneActiveDisease(data) {
    return this.http.post(`${BACK_END_URL}/clone_active_disease.php`, data);
  }
}
