import ObjectService from "../../../services/object.service";
import { getFilterList } from "./get-filter-list";

export const SORT_SWITCH_FILTER_FETCH = "SORT_SWITCH_FILTER_FETCH";
export const SORT_SWITCH_FILTER_SUCCESS = "SORT_SWITCH_FILTER_SUCCESS";
export const SORT_SWITCH_FILTER_FAILED = "SORT_SWITCH_FILTER_FAILED";

const objectService = new ObjectService();

export const sortSwitchFilter = (id, upperId) => {
  return (dispatch) => {
    dispatch(sortSwitchFilterFetch());
    return objectService
      .sortSwitchObject({ filter_id: id, upper_id: upperId })
      .then(() => {
        dispatch(sortSwitchFilterSuccess());
        dispatch(getFilterList());
      })
      .catch((error) => {
        dispatch(sortSwitchFilterFailed(error));
        return Promise.reject(error);
      });
  };
};

export const sortSwitchFilterFetch = () => ({
  type: SORT_SWITCH_FILTER_FETCH,
});

export const sortSwitchFilterSuccess = (payload) => ({
  payload,
  type: SORT_SWITCH_FILTER_SUCCESS,
});

export const sortSwitchFilterFailed = (list) => ({
  list,
  type: SORT_SWITCH_FILTER_FAILED,
});
