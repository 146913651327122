import ClassService from "../../../services/class.service";
import * as ClassNormalize from "../normalize";

export const GET_CLASS_LIST_FETCH = "GET_CLASS_LIST_FETCH";
export const GET_CLASS_LIST_SUCCESS = "GET_CLASS_LIST_SUCCESS";
export const GET_CLASS_LIST_FAILED = "GET_CLASS_LIST_FAILED";

const classService = new ClassService();

export const getClassList = () => {
  return (dispatch) => {
    dispatch(getClassListFetch());
    return classService
      .getClassList()
      .then((resultList) => {
        const normalized = ClassNormalize.getClassList.success(resultList);
        dispatch(getClassListSuccess(normalized));

        return resultList;
      })
      .catch((error) => {
        dispatch(getClassListFailed(error));
        return Promise.reject(error);
      });
  };
};

export const getClassListFetch = () => ({
  type: GET_CLASS_LIST_FETCH,
});

export const getClassListSuccess = (payload) => ({
  payload,
  type: GET_CLASS_LIST_SUCCESS,
});

export const getClassListFailed = (list) => ({
  list,
  type: GET_CLASS_LIST_FAILED,
});
