import React from "react";
import { Table, Space, Popconfirm, Tooltip } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import classes from "./filter-list.module.scss";
import { TOOLTIP_BACKGROUND_COLOR } from "../../../../constants/common";

const FilterList = ({
  onEdit,
  onDelete,
  handleSwitch,
  isLoading,
  filterList,
}) => {
  const onSwitch = (item, index) => {
    const upperItem = filterList[index - 1];
    handleSwitch(item.id, upperItem.id);
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (_, record, index) => (
        <Space size="middle" className={classes.NameWrap}>
          <div className={classes.Text}>{record.name}</div>
          {index !== 0 && (
            <div className={classes.SwapIconWrap}>
              <Tooltip
                placement="top"
                title={"Move up"}
                color={TOOLTIP_BACKGROUND_COLOR}
              >
                <UpCircleOutlined
                  className={classes.SwapIcon}
                  onClick={() => onSwitch(record, index)}
                />
              </Tooltip>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "150px",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            placement="bottom"
            title="Are you sure to delete this filter?"
            onConfirm={() => onDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined className={classes.DeleteIcon} />
          </Popconfirm>

          <EditOutlined
            className={classes.EditIconIcon}
            onClick={() => onEdit(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        loading={isLoading}
        bordered
        columns={columns}
        dataSource={filterList}
        className={classes.ListItems}
        scroll={{ x: 800, y: "calc(100vh - 250px)" }}
        pagination={false}
      />
    </div>
  );
};

export default FilterList;
