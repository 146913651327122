import { mergeDeepLeft, pathOr, prop } from "ramda";
import { GET_USER_LIST_SUCCESS } from "./actions/get-user-list";

export const initialState = {
  entities: {},
  ids: [],
};

const entities = (state = initialState.entities, action) => {
  switch (action.type) {
    case GET_USER_LIST_SUCCESS: {
      return mergeDeepLeft(
        pathOr({}, ["payload", "entities", "users"], action),
        state
      );
    }

    default:
      return state;
  }
};

const ids = (state = initialState.ids, action) => {
  switch (action.type) {
    case GET_USER_LIST_SUCCESS: {
      return pathOr([], ["payload", "result"], action);
    }

    default:
      return state;
  }
};

export default (state, action) => {
  return {
    entities: entities(prop("entities", state), action),
    ids: ids(prop("ids", state), action),
  };
};
