export const LOG_OUT_FETCH = "LOG_OUT";
export const LOG_OUT_SUCCESS = "LOG_OUT";
export const LOG_OUT_FAILED = "LOG_OUT";

export const Logout = (user) => {
  return (dispatch) => {
    dispatch(LogoutSuccess(user));
    localStorage.removeItem("user");
    return Promise.resolve();
  };
};

export const LogoutFetch = () => ({
  user: null,
  type: LOG_OUT_FETCH,
});

export const LogoutSuccess = () => ({
  user: null,
  type: LOG_OUT_SUCCESS,
});

export const LogoutFailed = () => ({
  user: null,
  type: LOG_OUT_FAILED,
});
