import FilterService from "../../../services/filter.service";
import { getFilterList } from "./get-filter-list";

export const EDIT_FILTER_FETCH = "EDIT_FILTER_FETCH";
export const EDIT_FILTER_SUCCESS = "EDIT_FILTER_SUCCESS";
export const EDIT_FILTER_FAILED = "EDIT_FILTER_FAILED";

const filterService = new FilterService();

export const editFilter = (data) => {
  return (dispatch) => {
    dispatch(editFilterFetch());
    return filterService
      .updateFilter({
        id: data.id,
        name: data.name,
        horizontal_vertical: data.horizontal_vertical
      })
      .then(() => {
        dispatch(editFilterSuccess());
        dispatch(getFilterList());
      })
      .catch((error) => {
        dispatch(editFilterFailed(error));
        return Promise.reject(error);
      });
  };
};

export const editFilterFetch = () => ({
  type: EDIT_FILTER_FETCH,
});

export const editFilterSuccess = (payload) => ({
  payload,
  type: EDIT_FILTER_SUCCESS,
});

export const editFilterFailed = (list) => ({
  list,
  type: EDIT_FILTER_FAILED,
});
