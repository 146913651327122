import React, { useEffect, useState } from "react";
import {useDispatch} from "react-redux";
import { getDiseaseDetail } from "../../../../reducers/disease/actions/get-disease-detail";

const withLoadData = (WrapperComponent) => {
  const WithLoadData = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      if (props.diseaseItem) {
        setIsLoading(true);
        dispatch(getDiseaseDetail(props.diseaseItem.disease_id)).finally(() => {
          setIsLoading(false);
        });
      }
    }, []);

    if (!props.visible) {
      return null;
    }

    return <WrapperComponent {...props} isLoading={isLoading} />;
  };

  return WithLoadData;
};

export default withLoadData;
