import FilterService from "../../../services/filter.service";
import {getFilterList} from "./get-filter-list";

export const INSERT_FILTER_FETCH = "INSERT_FILTER_FETCH";
export const INSERT_FILTER_SUCCESS = "INSERT_FILTER_SUCCESS";
export const INSERT_FILTER_FAILED = "INSERT_FILTER_FAILED";

const filterService = new FilterService();

export const insertFilter = (data) => {
  return (dispatch) => {
    dispatch(insertFilterFetch());
    return filterService
      .insertFilter({
        name: data.name,
        horizontal_vertical: data.horizontal_vertical
      })
      .then(() => {
        dispatch(insertFilterSuccess());
        dispatch(getFilterList());
      })
      .catch((error) => {
        dispatch(insertFilterFailed(error));
        return Promise.reject(error);
      });
  };
};

export const insertFilterFetch = () => ({
  type: INSERT_FILTER_FETCH,
});

export const insertFilterSuccess = (payload) => ({
  payload,
  type: INSERT_FILTER_SUCCESS,
});

export const insertFilterFailed = (list) => ({
  list,
  type: INSERT_FILTER_FAILED,
});
