import React from "react";
import { connect, useDispatch } from "react-redux";

import classes from "./register-password.module.scss";
import { useSweetAlert } from "../../helpers/useSweetAlert";
import RegisterPasswordForm from "./sections/register-password-form";
import { setPassword } from "../../reducers/auth/actions/reset-password";
import {useHistory} from "react-router";
import {PAGES} from "../../constants/pages";
import {Logout} from "../../reducers/user/actions/logout";
import {showErrorFromServerError} from "../../helpers/request";

function RegisterPassword({ match }) {
  const alert = useSweetAlert();
  const dispatch = useDispatch();
  const history =useHistory();

  const submitForm = (data) => {
    alert.progress("Processing").fire();
    dispatch(
      setPassword({
        hash: match.params.hash,
        password: data.password,
      })
    )
      .then(() => {
        alert.progress().close();
        alert
          .success("Updated Successfully!")
          .fire()
          .then(() => {
            dispatch(Logout()).then(() => {
              setTimeout(() => {
                history.push(PAGES.LOGIN.path);
              }, 1000);
            });
          });
      }).catch(showErrorFromServerError(alert));
  };

  return (
    <div className={classes.Background}>
      <RegisterPasswordForm onSubmit={submitForm} />
    </div>
  );
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPassword);
