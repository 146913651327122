import { initialState } from "./index";
import { createSelector } from "reselect";
import { sort } from "ramda";
import {sortFunc} from "../../helpers/common";

export const selectClass = (state) => state.ClassReducer || initialState;

export const selectEntities = createSelector(
  selectClass,
  (classState) => classState.entities
);

export const selectIds = createSelector(
  selectClass,
  (classState) => classState.ids
);

export const selectClasses = createSelector(
  selectEntities,
  selectIds,
  (entities, ids) => {
    const list = ids.map((id) => entities[id]);
    return sort(sortFunc, list);
  }
);
