import React from "react";

const withLoadData = (WrapperComponent) => {
  const WithLoadData = (props) => {
    if (!props.visible) {
      return null;
    }

    return <WrapperComponent {...props} />;
  };

  return WithLoadData;
};

export default withLoadData;