import { LOG_IN_FAILED, LOG_IN_SUCCESS } from "./actions/login";
import { prop } from "ramda";
import {LOG_OUT_SUCCESS} from "../user/actions/logout";

export const initialState = {
  user: {
    id: localStorage.getItem("user"),
  },
  userInfo: {},
};

const removeUserFromStorage = () => localStorage.removeItem("user");

const user = (state = initialState.user, action) => {
  switch (action.type) {
    case LOG_IN_SUCCESS:
      return action.user;

    case LOG_IN_FAILED:
    case LOG_OUT_SUCCESS:
      removeUserFromStorage();
      return { ...state, id: false };

    default:
      return state;
  }
};

const userInfo = (state = initialState.userInfo, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default (state, action) => {
  return {
    user: user(prop("user", state), action),
    userInfo: userInfo(prop("userInfo", state), action),
  };
};
