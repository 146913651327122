import React, {useCallback, useEffect, useMemo, useState} from "react";
import classes from "./disease-detail.module.scss";
import Filter from "./filter";
import { SEVERENESS, SIGNIFICANCES } from "../../../constants/disease";
import { useDispatch, useSelector } from "react-redux";
import { getDiseaseDetail } from "../../../reducers/disease/actions/get-disease-detail";
import { selectDiseaseDetails } from "../../../reducers/disease/selectors";
import { pathOr, propOr } from "ramda";
import {Col, Row, Spin, Tabs} from "antd";
import classNames from "classnames";
import { showErrorFromServerError } from "../../../helpers/request";

const { TabPane } = Tabs;

const DiseaseDetail = ({ match }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectDiseaseDetails)(match.params.id);

  const [isLoading, setIsLoading] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({
    isActiveState: true,
    severeness: pathOr(null, ["active_severeness", 0], data),
  });

  const onChangeIsActive = (value) => {
    setCurrentFilter((prev) => {
      return {
        isActiveState: value.target.value,
        severeness: prev.severeness,
      };
    });
  };

  const onChangeTab = (value) => {
    setCurrentFilter((prev) => {
      return {
        isActiveState: prev.isActiveState,
        severeness: value,
      };
    });
  };

  const getAllItemList = (data) => {
    let list = [];
    data.forEach((item) => {
      list = list.concat(
        item.results.map((sub) => {
          sub.severeness = item.severeness;
          return sub;
        })
      );
    });
    return list;
  };

  const currentList = useMemo(() => {
    const currentDiseases = currentFilter.isActiveState
      ? propOr([], "active_disease_severeness", data)
      : propOr([], "in_past_disease_severeness", data);

    return currentFilter.severeness
      ? getAllItemList(currentDiseases).filter(
          (item) => currentFilter.severeness === item.severeness
        )
      : getAllItemList(currentDiseases);
  }, [data, currentFilter]);

  const currentTabs = useMemo(() => {
    return propOr([], "severeness_stages", data).map(
      (item) => SEVERENESS[item.toString()]
    );
  }, [data]);

  const getTabBackgroundColor = useCallback(
    (severeness) => {
      const list = currentFilter.currentState
        ? propOr([], "in_past_disease_severeness", data)
        : propOr([], "active_disease_severeness", data);

      const item = list.find((item) => item.severeness === severeness);
      const maxSevereness = propOr(null, "severeness_max_significance", item);
      return maxSevereness
        ? SIGNIFICANCES[maxSevereness].background
        : "#DDDDDD";
    },
    [data]
  );

  const getTabColor = useCallback(
    (severeness) => {
      const list = currentFilter.currentState
        ? propOr([], "in_past_disease_severeness", data)
        : propOr([], "active_disease_severeness", data);

      const item = list.find((item) => item.severeness === severeness);
      const maxSevereness = propOr(null, "severeness_max_significance", item);
      return maxSevereness ? SIGNIFICANCES[maxSevereness].color : "#9D9D9D";
    },
    [data, currentFilter]
  );

  useEffect(() => {
    setCurrentFilter((prev) => {
      return {
        ...prev,
        severeness: pathOr(0, ["severeness_stages", 0], data).toString(),
      };
    });
  }, [currentFilter.isActiveState, data]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getDiseaseDetail(match.params.id))
      .catch(showErrorFromServerError(alert))
      .finally(() => {
        setIsLoading(false);
      });
  }, [match.params.id]);

  return (
    <div className={classes.Container}>
      <Filter
        filters={currentFilter}
        onChangeIsActive={onChangeIsActive}
        diseaseName={data.name}
      />

      {isLoading ? (
        <div className={classes.SpinWrap}>
          <Spin size="large" />
        </div>
      ) : (
        <Tabs
          activeKey={currentFilter.severeness}
          type="card"
          className={classNames("disease-tabs", classes.TabsWrap)}
          onChange={onChangeTab}
        >
          {!currentTabs.length && <div className={classes.NoData}>No data</div>}
          {currentTabs.map((item) => (
            <TabPane
              key={item.value}
              tab={
                <div
                  style={{
                    backgroundColor: getTabBackgroundColor(item.value),
                    borderColor: getTabColor(item.value),
                  }}
                  className={classNames(classes.TabName, {
                    [classes.ActiveTab]:
                      item.value === currentFilter.severeness,
                  })}
                >
                  <img src={item.icon} className={classes.Chart} />
                  <span style={{ color: getTabColor(item.value) }}>{item.name}</span>
                </div>
              }
            >
              <div className={classes.ContentWrap}>
                {!currentList.length && (
                  <div className={classes.NoData}>No data</div>
                )}
                <Row gutter={[16, 10]}>
                    {currentList.map((item) => (
                      <Col key={item.disease_id} className="gutter-row" xs={12} sm={8} md={8} lg={6} xl={6}>
                      <div
                        className={classes.ContentItem}
                        style={{
                          borderColor: SIGNIFICANCES[item.score].color,
                          background: SIGNIFICANCES[item.score].background,
                        }}
                      >
                        <div className={classes.TextWrap}>
                          <div className={classes.Title}>{item.title}</div>
                          <div className={classes.Description}>
                            {item.description}
                          </div>
                        </div>
                        <div
                          className={classes.Score}
                          style={{ color: SIGNIFICANCES[item.score].color }}
                        >
                          {item.score}
                        </div>
                      </div>
                      </Col>

                    ))}
                </Row>

              </div>
            </TabPane>
          ))}
        </Tabs>
      )}
    </div>
  );
};

export default DiseaseDetail;
