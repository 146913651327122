import HttpService from "./http.service";
import { BACK_END_URL } from "../environments";
import UserTransform from "./user.transform";

export default class UserService {
  constructor() {
    this.http = new HttpService();
    this.transform = new UserTransform();
  }

  getUserList() {
    return this.http
      .get(`${BACK_END_URL}/user_list.php`)
      .then((res) => this.transform.getUserList().success(res));
  }

  insertUser(data) {
    return this.http.post(`${BACK_END_URL}/insert_user.php`, data);
  }
}
