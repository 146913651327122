import React, {useMemo, useState} from "react";
import { connect, useDispatch } from "react-redux";

import classes from "./forgot-password.module.scss";
import { useSweetAlert } from "../../helpers/useSweetAlert";
import { showErrorFromServerError } from "../../helpers/request";
import ForgotPasswordForm from "./sections/forgot-password-form";
import { forgotPassword } from "../../reducers/auth/actions/forgot-password";
import ForgotPasswordSuccessfully from "./sections/forgot-password-successfully";
import {useHistory} from "react-router";
import {pathOr} from "ramda";

function ForgotPassword() {
  const history = useHistory();
  const alert = useSweetAlert();
  const dispatch = useDispatch();
  const [isSent, setIsSent] = useState(false);

  const email = useMemo(() => {
    return pathOr(null, ["location", "state", "email"], history);
  }, [history]);

  const submitForm = (data) => {
    alert.progress("Processing").fire();
    dispatch(
      forgotPassword({
        email: data.email,
      })
    )
      .then(() => {
        alert.progress().close();
        alert
          .success("Updated Successfully!")
          .fire()
          .then(() => {
            setIsSent(true);
          });
      })
      .catch(showErrorFromServerError(alert));
  };

  return (
    <div className={classes.Background}>
      {isSent ? (
        <ForgotPasswordSuccessfully/>
      ) : (
        <ForgotPasswordForm email={email} onSubmit={submitForm} />
      )}
    </div>
  );
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
