import UserService from "../../../services/user.service";
import * as UserNormalize from "../normalize";

export const GET_USER_LIST_FETCH = "GET_USER_LIST_FETCH";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILED = "GET_USER_LIST_FAILED";

const userService = new UserService();

export const getUserList = () => {
  return (dispatch) => {
    dispatch(getUserListFetch());
    return userService
      .getUserList()
      .then((resultList) => {
        const normalized = UserNormalize.getUserList.success(resultList);
        dispatch(getUserListSuccess(normalized));

        return resultList;
      })
      .catch((error) => {
        dispatch(getUserListFailed(error));
        return Promise.reject(error);
      });
  };
};

export const getUserListFetch = () => ({
  type: GET_USER_LIST_FETCH,
});

export const getUserListSuccess = (payload) => ({
  payload,
  type: GET_USER_LIST_SUCCESS,
});

export const getUserListFailed = (list) => ({
  list,
  type: GET_USER_LIST_FAILED,
});
