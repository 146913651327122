import AuthService from "../../../services/auth.service";

export const LOG_IN_FETCH = "LOG_IN_FETCH";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_FAILED = "LOG_IN_FAILED";

const authService = new AuthService();

export const Login = (user) => {
  return (dispatch) => {
    dispatch(LoginFetch());
    return new Promise((resolve, reject) => {
      authService
        .login(user)
        .then(({ success }) => {
          if (success) {
            dispatch(LoginSuccess({ id: true }));
            localStorage.setItem("user", user);
            resolve(user);
          } else {
            dispatch(LoginFailed({ id: null, error: true }));
            reject({ id: null, error: true });
          }
        })
        .catch((error) => {
          dispatch(LoginFailed({ id: null, error }));
          reject({ id: null, error });
        });
    });
  };
};

export const LoginFetch = (user) => ({
  user,
  type: LOG_IN_FETCH,
});

export const LoginSuccess = (user) => ({
  user,
  type: LOG_IN_SUCCESS,
});

export const LoginFailed = (user) => ({
  user,
  type: LOG_IN_FAILED,
});
