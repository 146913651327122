import {CHART_1, CHART_2, CHART_3, CHART_4, CHART_5, CHART_6} from "./images";

export const ACTIVE_DISEASE_VALUES = {
  activeDisease: {
    value: 2
  },
  inThePastDisease: {
    value: 1
  },
  both: {
    value: 3
  }
}


export const FILTERS = [
  {
    id: 1,
    name: "Overview",
  },
  {
    id: 2,
    name: "Risks",
  },
  {
    id: 3,
    name: "No iSBCS",
  },
  {
    id: 4,
    name: "Pharmaceutical teratment",
  },
  {
    id: 5,
    name: "Follow-up",
  },
  {
    id: 6,
    name: "During surgery",
  },
];

export const DETAIL_CONTENT = [
  {
    id: 1,
    name: "Overview",
    significance: "2",

  },
  {
    id: 2,
    name: "Risks",
    significance: "3",
  },
  {
    id: 3,
    name: "No iSBCS",
    significance: "5",
  },
  {
    id: 4,
    name: "Pharmaceutical teratment",
    significance: "2",
  },
  {
    id: 5,
    name: "Follow-up",
    significance: "1",
  },
  {
    id: 6,
    name: "During surgery",
    significance: "4",
  },
];

export const SIGNIFICANCES = {
  "1": {
    color: "#D0C9C0",
    background: "#F1EEE9",
    value: "1",
  },
  "2": {
    color: "#B4E197",
    background: "#CEE5D0",
    value: "2",
  },
  "3": {
    color: "#FFCD38",
    background: "#EEF3D2",
    value: "3",
  },
  "4": {
    color: "#EC994B",
    background: "#FED2AA",
    value: "4"
  },
  "5": {
    color: "#EB5353",
    background: "#FFAFAF",
    value: "5"
  },
};

export const SEVERENESS = {
  "0": {
    color: "#FFEDD3",
    background: "#F1EEE9",
    value: "0",
    icon: CHART_1,
    name: "0"
  },
  "1": {
    color: "#FFBC80",
    background: "#D8F8B7",
    value: "1",
    icon: CHART_2,
    name: "1"
  },
  "2": {
    color: "#FF9F45",
    background: "#C1F4C5",
    value: "2",
    icon: CHART_3,
    name: "2"
  },
  "3": {
    color: "#F76E11",
    background: "#EEF3D2",
    value: "3",
    icon: CHART_4,
    name: "3",
  },
  "4": {
    color: "#F05454",
    background: "#FED2AA",
    value: "4",
    icon: CHART_5,
    name: "4"
  },
  "5": {
    color: "#CE1212",
    background: "#FFAFAF",
    value: "5",
    icon: CHART_6,
    name: "5"
  },

};

export const MODALS = {
  saveDiseaseModal: "SAVE_DISEASE_MODAL",
};
