import ClassService from "../../../services/class.service";
import { getClassList } from "./get-class-list";

export const INSERT_CLASS_FETCH = "INSERT_CLASS_FETCH";
export const INSERT_CLASS_SUCCESS = "INSERT_CLASS_SUCCESS";
export const INSERT_CLASS_FAILED = "INSERT_CLASS_FAILED";

const classService = new ClassService();

export const insertClass = (data) => {
  return (dispatch) => {
    dispatch(insertClassFetch());
    return classService
      .insertClass({
        name: data.name,
        description: data.description,
      })
      .then(() => {
        dispatch(insertClassSuccess());
        dispatch(getClassList());
      })
      .catch((error) => {
        dispatch(insertClassFailed(error));
        return Promise.reject(error);
      });
  };
};

export const insertClassFetch = () => ({
  type: INSERT_CLASS_FETCH,
});

export const insertClassSuccess = (payload) => ({
  payload,
  type: INSERT_CLASS_SUCCESS,
});

export const insertClassFailed = (list) => ({
  list,
  type: INSERT_CLASS_FAILED,
});
