import { initialState } from "./index";
import {createSelector} from "reselect";

export const selectUser = (state) => state.UserReducer || initialState;

export const selectEntities = createSelector(
  selectUser,
  (userState) => userState.entities
);

export const selectIds = createSelector(
  selectUser,
  (userState) => userState.ids
);

export const selectUsers = createSelector(
  selectEntities,
  selectIds,
  (entities, ids) => {
    return ids.map((id) => entities[id]);
  }
);
