import ClassService from "../../../services/class.service";
import {getClassList} from "./get-class-list";

export const EDIT_CLASS_FETCH = "EDIT_CLASS_FETCH";
export const EDIT_CLASS_SUCCESS = "EDIT_CLASS_SUCCESS";
export const EDIT_CLASS_FAILED = "EDIT_CLASS_FAILED";

const classService = new ClassService();

export const editClass = (data) => {
  return (dispatch) => {
    dispatch(editClassFetch());
    return classService
      .updateClass({
        id: data.id,
        name: data.name,
        description: data.description
      })
      .then(() => {
        dispatch(editClassSuccess());
        dispatch(getClassList());
      })
      .catch((error) => {
        dispatch(editClassFailed(error));
        return Promise.reject(error);
      });
  };
};

export const editClassFetch = () => ({
  type: EDIT_CLASS_FETCH,
});

export const editClassSuccess = (payload) => ({
  payload,
  type: EDIT_CLASS_SUCCESS,
});

export const editClassFailed = (list) => ({
  list,
  type: EDIT_CLASS_FAILED,
});
