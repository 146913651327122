import { initialState } from "./index";
import {createSelector} from "reselect";

export const selectFilter = (state) => state.FilterReducer || initialState;

export const selectEntities = createSelector(
  selectFilter,
  (filterState) => filterState.entities
);

export const selectIds = createSelector(
  selectFilter,
  (filterState) => filterState.ids
);

export const selectFilters = createSelector(
  selectEntities,
  selectIds,
  (entities, ids) => {
    return ids.map((id) => entities[id]);
  }
);
