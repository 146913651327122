import * as DiseaseNormalize from "../normalize";
import DiseaseService from "../../../services/disease.service";

export const FILTERING_DISEASE_FETCH = "FILTERING_DISEASE_FETCH";
export const FILTERING_DISEASE_SUCCESS = "FILTERING_DISEASE_SUCCESS";
export const FILTERING_DISEASE_FAILED = "FILTERING_DISEASE_FAILED";

const diseaseService = new DiseaseService();

export const filteringDisease = (filters) => {
  return (dispatch) => {
    dispatch(filteringDiseaseFetch());
    return diseaseService
      .filterDiseases(filters)
      .then((resultList) => {
        const normalized = DiseaseNormalize.getFilteringDisease.success(
          resultList.diseases
        );

        dispatch(filteringDiseaseSuccess({
          ...normalized,
          ...resultList
        }));

        return resultList;
      })
      .catch((error) => {
        dispatch(filteringDiseaseFailed(error));
        return Promise.reject(error);
      });
  };
};

export const filteringDiseaseFetch = () => ({
  type: FILTERING_DISEASE_FETCH,
});

export const filteringDiseaseSuccess = (payload) => ({
  payload,
  type: FILTERING_DISEASE_SUCCESS,
});

export const filteringDiseaseFailed = (list) => ({
  list,
  type: FILTERING_DISEASE_FAILED,
});
