import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showErrorFromServerError } from "../../../helpers/request";
import { useSweetAlert } from "../../../helpers/useSweetAlert";
import classes from "./class.module.scss";
import ClassList from "./class-list";
import MainButton from "../../../components/main-button";
import { UserAddOutlined } from "@ant-design/icons";
import { MODALS } from "../../../constants/class";
import SaveClassModal from "./save-class-modal";
import { getClassList } from "../../../reducers/class/actions/get-class-list";
import {insertClass} from "../../../reducers/class/actions/insert-class";
import {editClass} from "../../../reducers/class/actions/edit-class";
import {deleteClass} from "../../../reducers/class/actions/delete-class";
import {sortSwitchClass} from "../../../reducers/class/actions/sort-switch-class";

const Class = () => {
  const alert = useSweetAlert();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  const getListClass = () => {
    setIsLoading(true);
    dispatch(getClassList())
      .catch(showErrorFromServerError(alert))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onEdit = (item) => {
    setCurrentItem(item);
    setModal(MODALS.saveClassModal);
  }

  const onHide = () => {
    setModal(null);
    setCurrentItem(null);
  }

  const onSaveClass = (data) => {
    if (!data.id) {
      alert.progress("Adding").fire();
      dispatch(insertClass(data))
        .then(() => {
          alert.progress().close();
          alert
            .success("Added Successfully!")
            .fire()
            .then(() => {
              setModal(null);
            });
        })
        .catch(showErrorFromServerError(alert));
    } else {
      alert.progress("Editing").fire();
      dispatch(editClass(data))
        .then(() => {
          alert.progress().close();
          alert
            .success("Edited Successfully!")
            .fire()
            .then(() => {
              setModal(null);
            });
        })
        .catch(showErrorFromServerError(alert));
    }
  };

  const handleSwitch = (id, upperId) => {
    alert.progress("Processing").fire();
    dispatch(sortSwitchClass(id, upperId))
      .then(() => {
        alert.progress().close();
        alert
          .success("Updated Successfully!")
          .fire()
          .then(() => {
            setModal(null);
          });
      })
      .catch(showErrorFromServerError(alert));
  }

  const handleDelete = (data) => {
    alert.progress("Deleting").fire();
    dispatch(deleteClass(data.class_id))
      .then(() => {
        alert.progress().close();
        alert
          .success("Deleted Successfully!")
          .fire()
          .then(() => {
            setModal(null);
          });
      })
      .catch(showErrorFromServerError(alert));
  }

  useEffect(() => {
    getListClass();
  }, []);

  return (
    <div className={classes.Container}>
      <div className={classes.ActionWrap}>
        <MainButton
          className={classes.NewUserButton}
          onClick={() => setModal(MODALS.saveClassModal)}
        >
          <UserAddOutlined /> Add Class
        </MainButton>
      </div>
      <ClassList onEdit={onEdit} onDelete={handleDelete} handleSwitch={handleSwitch} isLoading={isLoading}/>

      {modal === MODALS.saveClassModal && (
        <SaveClassModal
          onSubmit={onSaveClass}
          visible={modal === MODALS.saveClassModal}
          onHide={onHide}
          classItem={currentItem}
        />
      )}
    </div>
  );
};

export default Class;
