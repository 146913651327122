import React from "react";
import classes from "./filter.module.scss";
import { Link } from "react-router-dom";
import { DoubleLeftOutlined } from "@ant-design/icons";
import { Radio } from "antd";

const Filter = ({
  filters,
  onChangeIsActive,
  diseaseName,
}) => {

  return (
    <div className={classes.FilterWrap}>
      <div className={classes.Filter}>
        <div className={classes.FirstFilter}>
          <div className={classes.DiseaseBox}>
            <div className={classes.DiseaseName}>
              {diseaseName}
            </div>
          </div>
        </div>
        <div className={classes.RightWrap}>
          <div className={classes.BackToList}>
            <Link to={`/disease`} className={classes.LinkText}>
              <DoubleLeftOutlined />
              Back to list
            </Link>
          </div>
          <Radio.Group
            value={filters.isActiveState}
            onChange={onChangeIsActive}
          >
            <Radio value={true} className={classes.CheckBoxItem}>
              Active disease
            </Radio>
            <Radio value={false} className={classes.CheckBoxItem}>
              Disease in the past
            </Radio>
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};

export default Filter;
