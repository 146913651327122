import React, { useCallback, useEffect, useMemo, useState } from "react";
import classes from "./disease-detail.module.scss";
import Filter from "./filter";
import { useDispatch, useSelector } from "react-redux";
import { pathOr, propOr, sort } from "ramda";
import { Spin, Tabs } from "antd";
import classNames from "classnames";
import { selectDiseaseDetails } from "../../../../reducers/disease/selectors";
import { getDiseaseDetail } from "../../../../reducers/disease/actions/get-disease-detail";
import {
  ACTIVE_DISEASE_VALUES,
  SEVERENESS,
  SIGNIFICANCES,
} from "../../../../constants/disease";
import { useHistory, useRouteMatch } from "react-router";
import { selectFilters } from "../../../../reducers/filter/selectors";
import SaveFilterItem from "./save-filter-item";
import {
  CHART_1,
  CHART_2,
  CHART_3,
  CHART_4,
  CHART_5,
  CHART_6,
} from "../../../../constants/images";
import FilterItem from "./filter-item";
import { showErrorFromServerError } from "../../../../helpers/request";
import { useSweetAlert } from "../../../../helpers/useSweetAlert";
import { insertSignificance } from "../../../../reducers/disease/actions/insert-significance";
import { editSignificance } from "../../../../reducers/disease/actions/edit-significance";

const { TabPane } = Tabs;
const copyItems = [
  {
    value: 1,
    icon: CHART_1,
  },
  {
    value: 2,
    icon: CHART_2,
  },
  {
    value: 3,
    icon: CHART_3,
  },
  {
    value: 4,
    icon: CHART_4,
  },
  {
    value: 5,
    icon: CHART_5,
  },
  {
    value: 6,
    icon: CHART_6,
  },
];

const sortFn = (a, b) => {
  return Number(a) - Number(b);
};

const AdministrationDiseaseDetail = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const data = useSelector(selectDiseaseDetails)(match.params.id);
  const filters = useSelector(selectFilters);
  const [editItem, setEditItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const alert = useSweetAlert();

  const history = useHistory();
  const classIdDefault = useMemo(() => {
    return pathOr(null, ["location", "state", "classId"], history);
  }, [history]);

  // currentState = false => Active Disease
  // currentState = true => Disease in the past
  const [currentFilter, setCurrentFilter] = useState({
    currentState: false,
    severeness: pathOr(null, ["active_severeness", 0], data),
  });

  const onChangeIsActive = (value) => {
    setCurrentFilter((prev) => {
      return {
        currentState: value.target.value,
        severeness: prev.severeness,
      };
    });
  };

  const onChangeTab = (value) => {
    setCurrentFilter((prev) => {
      return {
        currentState: prev.currentState,
        severeness: value,
      };
    });
  };

  const getAllItemList = (data) => {
    let list = [];
    data.forEach((item) => {
      list = list.concat(
        item.results.map((sub) => {
          sub.severeness = item.severeness;
          return sub;
        })
      );
    });
    return list;
  };

  const currentList = useMemo(() => {
    const currentDiseases = currentFilter.currentState
      ? propOr([], "in_past_disease_severeness", data)
      : propOr([], "active_disease_severeness", data);

    const savedFilters = getAllItemList(currentDiseases).filter(
      (item) => currentFilter.severeness === item.severeness
    );

    const savedFilterTitles = savedFilters.map((item) => item.title);

    const unSavedFilters = filters.filter(
      (item) => !savedFilterTitles.includes(item.filter_name)
    );

    const sortedSavedFilters = sort(sortFn, savedFilters);
    const sortedUnSaveFilters = sort(sortFn, unSavedFilters);

    return sortedSavedFilters.concat(sortedUnSaveFilters);
  }, [data, currentFilter]);

  const currentTabs = useMemo(() => {
    return propOr([], "severeness_stages", data).map(
      (item) => SEVERENESS[item.toString()]
    );
  }, [data]);

  const copyableTabs = useMemo(() => {
    return currentTabs.filter(
      (item) => item.value !== currentFilter.severeness
    );
  }, [currentTabs, currentFilter]);

  const getTabBackgroundColor = useCallback(
    (severeness) => {
      const list = currentFilter.currentState
        ? propOr([], "in_past_disease_severeness", data)
        : propOr([], "active_disease_severeness", data);

      const item = list.find((item) => item.severeness === severeness);
      const maxSevereness = propOr(null, "severeness_max_significance", item);
      return maxSevereness
        ? SIGNIFICANCES[maxSevereness].background
        : "#DDDDDD";
    },
    [data]
  );

  const getTabColor = useCallback(
    (severeness) => {
      const list = currentFilter.currentState
        ? propOr([], "in_past_disease_severeness", data)
        : propOr([], "active_disease_severeness", data);

      const item = list.find((item) => item.severeness === severeness);
      const maxSevereness = propOr(null, "severeness_max_significance", item);
      return maxSevereness ? SIGNIFICANCES[maxSevereness].color : "#9D9D9D";
    },
    [data, currentFilter]
  );

  const isEditing = useMemo(() => {
    return Boolean(editItem);
  }, [editItem]);

  const onSaveSignification = (values) => {
    if (propOr(null, "id", values)) {
      alert.progress("Editing").fire();
      dispatch(editSignificance(values))
        .then(() => {
          alert.progress().close();
          alert
            .success("Edited Successfully!")
            .fire()
            .then(() => {
              setEditItem(null);
            });
        })
        .catch(showErrorFromServerError(alert))
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      alert.progress("Adding").fire();
      dispatch(insertSignificance(values))
        .then((res) => {
          const successArray = propOr([], "success", res);
          const numberIdsSuccess = successArray.filter(
            (item) => item === 1
          ).length;
          alert.progress().close();
          alert
            .success(
              `${numberIdsSuccess} ${
                numberIdsSuccess <= 1 ? "entry" : "entries"
              } added`
            )
            .fire()
            .then(() => {
              setEditItem(null);
            });
        })
        .catch(showErrorFromServerError(alert));
    }
  };

  useEffect(() => {
    setCurrentFilter((prev) => {
      return {
        ...prev,
        severeness: currentFilter.severeness
          ? currentFilter.severeness
          : pathOr(0, ["severeness_stages", 0], data).toString(),
      };
    });
  }, [currentFilter.currentState, data]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getDiseaseDetail(match.params.id))
      .catch(showErrorFromServerError(alert))
      .finally(() => {
        setIsLoading(false);
      });
  }, [match.params.id]);

  return (
    <div className={classes.Container}>
      <Filter
        filters={currentFilter}
        onChangeIsActive={onChangeIsActive}
        diseaseName={data.name}
        classIdDefault={classIdDefault}
        disabled={isEditing}
        diseaseId={match.params.id}
      />

      {isLoading ? (
        <div className={classes.SpinWrap}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {!currentTabs.length && <div className={classes.NoData}>No data</div>}
          <Tabs
            moreIcon={null}
            activeKey={currentFilter.severeness}
            type="card"
            className={classNames("disease-tabs", classes.TabsWrap)}
            onChange={onChangeTab}
          >
            {currentTabs.map((item) => (
              <TabPane
                disabled={isEditing}
                key={item.value}
                tab={
                  <div
                    style={{
                      backgroundColor: getTabBackgroundColor(item.value),
                      borderColor: getTabColor(item.value),
                    }}
                    className={classNames(classes.TabName, {
                      [classes.ActiveTab]:
                        item.value === currentFilter.severeness,
                    })}
                  >
                    <img src={item.icon} className={classes.Chart} />
                    <span style={{ color: getTabColor(item.value) }}>
                      {item.name}
                    </span>
                  </div>
                }
              >
                <div className={classes.ContentWrap}>
                  {currentList.map((item) => (
                    <>
                      {propOr(false, "score", item) ? (
                        <>
                          {propOr(null, "significance_id", editItem) ===
                          item.significance_id ? (
                            <SaveFilterItem
                              onSubmit={onSaveSignification}
                              name={item.title}
                              onCancelEdit={() => setEditItem(null)}
                              data={{
                                disease_id: match.params.id,
                                score: item.score,
                                description: item.description,
                                id: item.significance_id,
                              }}
                              copyableSevereness={copyableTabs}
                            />
                          ) : (
                            <FilterItem
                              color={SIGNIFICANCES[item.score].color}
                              bgColor={SIGNIFICANCES[item.score].background}
                              copyItems={copyItems}
                              title={item.title}
                              description={item.description}
                              score={item.score}
                              isDefault={true}
                              onItemClick={() => setEditItem(item)}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {propOr(null, "filter_id", editItem) ===
                          item.filter_id ? (
                            <SaveFilterItem
                              data={{
                                disease_id: match.params.id,
                                filter_id: item.filter_id,
                                active_disease: currentFilter.currentState
                                  ? ACTIVE_DISEASE_VALUES.inThePastDisease.value
                                  : ACTIVE_DISEASE_VALUES.activeDisease.value,
                                severeness: [currentFilter.severeness],
                              }}
                              onSubmit={onSaveSignification}
                              name={item.filter_name}
                              onCancelEdit={() => setEditItem(null)}
                              copyableSevereness={copyableTabs}
                            />
                          ) : (
                            <FilterItem
                              item={item}
                              copyItems={copyItems}
                              title={item.filter_name}
                              isDefault={false}
                              onItemClick={() => setEditItem(item)}
                            />
                          )}
                        </>
                      )}
                    </>
                  ))}
                </div>
              </TabPane>
            ))}
          </Tabs>
        </>
      )}
    </div>
  );
};

export default AdministrationDiseaseDetail;
