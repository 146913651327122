import React, { useEffect, useMemo, useState } from "react";
import classes from "./disease.module.scss";
import Filter from "./filter-items";
import { useDispatch, useSelector } from "react-redux";
import { filteringDisease } from "../../reducers/disease/actions/filtering-disease";
import DiseaseList from "./disease-list";
import { Spin } from "antd";
import { FILTER_POSITION } from "../../constants/filter";
import SplitPane from "react-split-pane";
import {
  selectCurrentActiveDiseaseState,
  selectCurrentHorizontalFilters,
  selectCurrentVerticalFilters,
} from "../../reducers/disease/selectors";
import { concat } from "ramda";
import FilterActiveState from "./filter-active-state";

const Disease = ({ props }) => {
  const dispatch = useDispatch();

  const filterVerticalData = useSelector(selectCurrentVerticalFilters);
  const filterHorizontalData = useSelector(selectCurrentHorizontalFilters);
  const activeDiseaseState = useSelector(selectCurrentActiveDiseaseState);

  const [isLoading, setIsLoading] = useState(false);

  const handleFilter = (filters) => {
    setIsLoading(true);
    dispatch(filteringDisease(filters))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleChangeFilterItems = (type, data) => {
    let activeFilters;
    if (type === FILTER_POSITION.vertical) {
      activeFilters = concat(
        data.filter((filter) => filter.is_active),
        currentFilters.horizontalFilters.filter((filter) => filter.is_active)
      );
    } else {
      activeFilters = concat(
        data.filter((filter) => filter.is_active),
        currentFilters.verticalFilters.filter((filter) => filter.is_active)
      );
    }

    if (activeFilters.length) {
      handleFilter({
        active_filters: activeFilters.map((item) => item.id),
        active_disease: currentFilters.activeDiseaseState,
      });
    } else {
      handleFilter({
        active_disease: currentFilters.activeDiseaseState,
      });
    }
  };

  const handleChangeFilterActiveState = (activeState) => {
    const activeFilters = concat(
      currentFilters.verticalFilters.filter((filter) => filter.is_active),
      currentFilters.horizontalFilters.filter((filter) => filter.is_active)
    );

    handleFilter({
      active_filters: activeFilters.map((item) => item.id),
      active_disease: activeState,
    });
  };

  const currentFilters = useMemo(() => {
    return {
      horizontalFilters: filterHorizontalData,
      verticalFilters: filterVerticalData,
      activeDiseaseState: activeDiseaseState,
    };
  }, [activeDiseaseState, filterVerticalData, filterHorizontalData]);

  useEffect(() => {
    handleFilter({});
  }, []);

  return (
    <div className={classes.DiseaseContainer}>
      {isLoading && (
        <div className={classes.SpinWrap}>
          <Spin size="large" />
        </div>
      )}

      <SplitPane
        split="vertical"
        minSize={240}
        defaultSize={parseInt(localStorage.getItem("vertical"), 10) || 240}
        onChange={(size) => localStorage.setItem("vertical", size)}
      >
        <div className={classes.VerticalFilterWrap}>
          <Filter
            isLoading={isLoading}
            position={FILTER_POSITION.vertical}
            currentFilterItems={currentFilters.verticalFilters}
            handleChangeFilterItems={handleChangeFilterItems}
          />
        </div>

        <SplitPane
          minSize={160}
          className={classes.DiseaseListWrap}
          split="horizontal"
          defaultSize={
            parseInt(localStorage.getItem("horizontal"), 10) || "170"
          }
          onChange={(size) => localStorage.setItem("horizontal", size)}
        >
          <div className={classes.HorizontalFilter}>
            <Filter
              isLoading={isLoading}
              position={FILTER_POSITION.horizontal}
              currentFilterItems={currentFilters.horizontalFilters}
              handleChangeFilterItems={handleChangeFilterItems}
            />

            <FilterActiveState
              isLoading={isLoading}
              currentActiveState={currentFilters.activeDiseaseState}
              handleChangeFilterActiveState={handleChangeFilterActiveState}
            />
          </div>
          <DiseaseList />
          <div />
        </SplitPane>
      </SplitPane>
    </div>
  );
};

export default Disease;
