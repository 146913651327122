import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showErrorFromServerError } from "../../../helpers/request";
import { useSweetAlert } from "../../../helpers/useSweetAlert";
import classes from "./disease.module.scss";
import MainButton from "../../../components/main-button";
import { UserAddOutlined } from "@ant-design/icons";
import { MODALS } from "../../../constants/disease";
import { editDisease } from "../../../reducers/disease/actions/edit-disease";
import { insertDisease } from "../../../reducers/disease/actions/insert-disease";
import { sortSwitchDisease } from "../../../reducers/disease/actions/sort-switch-disease";
import { deleteDisease } from "../../../reducers/disease/actions/delete-disease";
import { getDiseaseListByClass } from "../../../reducers/disease/actions/get-disease-list-by-class";
import { Select } from "antd";
import { selectClasses } from "../../../reducers/class/selectors";
import { getClassList } from "../../../reducers/class/actions/get-class-list";
import DiseaseList from "./disease-list";
import SaveDiseaseModal from "./save-disease-modal";
import {pathOr} from "ramda";

const { Option } = Select;

const Disease = () => {
  const classesList = useSelector(selectClasses);
  const [currentClass, setCurrentClass] = useState(pathOr(null, [0, "class_id"], classesList));

  const alert = useSweetAlert();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  const getListClass = () => {
    setIsLoading(true);
    return dispatch(getClassList())
      .catch(showErrorFromServerError(alert))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getListDisease = (data) => {
    setIsLoading(true);
    dispatch(getDiseaseListByClass(data))
      .catch(showErrorFromServerError(alert))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSelectClass = (data) => {
    setCurrentClass(data);
  };

  const onEdit = (item) => {
    setCurrentItem(item);
    setModal(MODALS.saveDiseaseModal);
  };

  const onHide = () => {
    setModal(null);
    setCurrentItem(null);
  };

  const onSaveDisease = (data) => {
    if (!data.id) {
      alert.progress("Adding").fire();
      dispatch(insertDisease(data))
        .then(() => {
          alert.progress().close();
          alert
            .success("Added Successfully!")
            .fire()
            .then(() => {
              setModal(null);
            });
        })
        .catch(showErrorFromServerError(alert));
    } else {
      alert.progress("Editing").fire();
      dispatch(editDisease(data))
        .then(() => {
          alert.progress().close();
          alert
            .success("Edited Successfully!")
            .fire()
            .then(() => {
              setModal(null);
            });
        })
        .catch(showErrorFromServerError(alert));
    }
  };

  const handleSwitch = (id, upperId) => {
    alert.progress("Processing").fire();
    dispatch(sortSwitchDisease(id, upperId, currentClass))
      .then(() => {
        alert.progress().close();
        alert
          .success("Updated Successfully!")
          .fire()
          .then(() => {
            setModal(null);
          });
      })
      .catch(showErrorFromServerError(alert));
  };

  const handleDelete = (data) => {
    alert.progress("Deleting").fire();
    dispatch(deleteDisease(data.disease_id, currentClass))
      .then(() => {
        alert.progress().close();
        alert
          .success("Deleted Successfully!")
          .fire()
          .then(() => {
            setModal(null);
          });
      })
      .catch(showErrorFromServerError(alert));
  };

  useEffect(() => {
    getListClass();
  }, []);

  useEffect(() => {
    if (currentClass === null && classesList.length) {
      setCurrentClass(classesList[0].class_id);
    }
  }, [currentClass, classesList]);

  useEffect(() => {
    if (currentClass) {
      getListDisease(currentClass);
    }
  }, [currentClass]);

  return (
    <div className={classes.Container}>
      <div className={classes.ActionWrap}>
        <Select
          showSearch
          className={classes.Select}
          value={currentClass}
          onSelect={onSelectClass}
        >
          {classesList.map((item) => (
            <Option value={item.class_id} key={item.class_id}>
              {item.class_name}
            </Option>
          ))}
        </Select>

        <div className={classes.ButtonWrap}>
          <MainButton
            className={classes.NewUserButton}
            onClick={() => setModal(MODALS.saveDiseaseModal)}
          >
            <UserAddOutlined /> Add Disease
          </MainButton>
        </div>

      </div>
      <DiseaseList
        onEdit={onEdit}
        onDelete={handleDelete}
        handleSwitch={handleSwitch}
        isLoading={isLoading}
        classId={currentClass}
      />

      {modal === MODALS.saveDiseaseModal && (
        <SaveDiseaseModal
          onSubmit={onSaveDisease}
          visible={modal === MODALS.saveDiseaseModal}
          onHide={onHide}
          diseaseItem={currentItem}
          currentClass={currentClass}
        />
      )}
    </div>
  );
};

export default Disease;
