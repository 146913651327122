import React from "react";
import classes from "./save-filter-item.module.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MainButton from "../../../../../components/main-button";
import * as yup from "yup";
import Textarea from "../../../../../components/form-items/textarea";
import {
  always,
  applySpec,
  defaultTo,
  either,
  mergeLeft,
  pipe,
  prop,
  values,
} from "ramda";
import { connect } from "react-redux";
import {ACTIVE_DISEASE_VALUES, SIGNIFICANCES} from "../../../../../constants/disease";
import { CheckCircleOutlined, CloseOutlined } from "@ant-design/icons";

const schema = (id) => {
  if (id) {
    return yup.object().shape({
      disease_id: yup.string().required(),
      score: yup.string().required(),
      description: yup.string().required(),
    });
  } else {
    return yup.object().shape({
      disease_id: yup.string().required(),
      filter_id: yup.string().required(),
      active_disease: yup.string().required(),
      severeness: yup.array().required(),
      score: yup.string().required(),
      description: yup.string().required(),
    });
  }
};

const SaveFilterItem = ({
  name,
  defaultValues,
  onCancelEdit,
  onSubmit,
  copyableSevereness,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema(defaultValues.id)),
  });

  const watchScore = watch("score", defaultValues.score);
  const watchSevereness = watch("severeness", defaultValues.severeness);

  const onChangeScore = (value) => {
    setValue("score", value, { shouldDirty: true });
  };

  const onChangeSevereness = (value) => {
    const newList = watchSevereness.includes(value)
      ? watchSevereness.filter((item) => item !== value)
      : [...watchSevereness, value];
    setValue("severeness", newList, { shouldDirty: true });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.ContentItem}>
        <div className={classes.CancelIconWrap} onClick={onCancelEdit}>
          <CloseOutlined className={classes.CancelIcon} />
        </div>
        <div className={classes.LeftWrap}>
          {values(SIGNIFICANCES).map((item) => (
            <div
              style={{ background: item.background }}
              className={classes.ScoreItem}
              onClick={() => onChangeScore(item.value)}
            >
              {watchScore === item.value && (
                <CheckCircleOutlined className={classes.CheckedScore} />
              )}
            </div>
          ))}
        </div>

        <div className={classes.RightWrap}>
          <div className={classes.Title}>{name}</div>
          <div className={classes.Description}>
            <Textarea control={control} name="description" rows={2} />
          </div>
          <div className={classes.Footer}>
            {!defaultValues.id && (
              <div className={classes.CopyWrap}>
                <div className={classes.CopyText}>Copy also to following:</div>
                <div className={classes.CopyIconWrap}>
                  {copyableSevereness.map((item) => (
                    <div
                      className={classes.CopyIcon}
                      style={{ backgroundColor: item.color }}
                      onClick={() => onChangeSevereness(item.value)}
                    >
                      {watchSevereness.includes(item.value) && (
                        <CheckCircleOutlined className={classes.CheckedCopy} />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className={classes.SaveBtnWrap}>
              <MainButton type="submit" disabled={isSubmitting || !isDirty}>
                Save
              </MainButton>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state, props) => {
  const defaultValues = applySpec({
    disease_id: either(prop("disease_id"), always(null)),
    filter_id: either(prop("filter_id"), always("")),
    score: either(prop("score"), always("1")),
    description: either(prop("description"), always("")),
    active_disease: either(
      prop("active_disease"),
      always(ACTIVE_DISEASE_VALUES.activeDisease.value)
    ),
    severeness: either(prop("severeness"), always([])),
  })(props.data);

  return {
    defaultValues: pipe(defaultTo({}), mergeLeft(defaultValues))(props.data),
  };
};

export default connect(mapStateToProps, undefined)(SaveFilterItem);
