import Swal from "sweetalert2";

export default class SweetAlert {
  title;
  confirmText;
  cancelText;
  showCancelButton;
  icon;
  text;
  onConfirm;
  onCancel;
  confirmMessageText;
  cancelMessageText;
  progressBar;
  timerProgressBar;
  showConfirmButton;
  timer;
  constructor({
    title = "Are you sure?",
    confirmText = "Yes",
    cancelText = "No",
    showCancelButton = true,
    icon = "warning",
    text = "",
    onConfirm,
    onCancel,
    confirmMessageText = "",
    cancelMessageText = "",
    confirmMessageTitle = "",
    cancelMessageTitle = "",
    progressBar = false,
    allowEscapeKey = true,
    allowOutsideClick = true,
    showConfirmButton = true,
    timer = undefined,
    timerProgressBar = false,
  }) {
    this.title = title;
    this.confirmText = confirmText;
    this.cancelText = cancelText;
    this.showCancelButton = showCancelButton;
    this.showConfirmButton = showConfirmButton;
    this.icon = icon;
    this.text = text;
    this.onConfirm = onConfirm;
    this.onCancel = onCancel;
    this.confirmMessageText = confirmMessageText;
    this.cancelMessageText = cancelMessageText;
    this.confirmMessageTitle = confirmMessageTitle;
    this.cancelMessageTitle = cancelMessageTitle;
    this.progressBar = progressBar;
    this.allowEscapeKey = allowEscapeKey;
    this.allowOutsideClick = allowOutsideClick;
    this.timer = timer;
    this.timerProgressBar = timerProgressBar;
  }

  fire() {
    return Swal.fire({
      title: this.title,
      text: this.text,
      icon: this.icon,
      timer: this.timer,
      showCancelButton: this.showCancelButton,
      showConfirmButton: this.showConfirmButton,
      confirmButtonText: this.confirmText,
      cancelButtonText: this.cancelText,
      timerProgressBar: this.timerProgressBar,
      allowEscapeKey: this.allowEscapeKey,
      allowOutsideClick: this.allowOutsideClick,
      didOpen: () => {
        if (this.progressBar) {
          Swal.showLoading();
        }
      },
    }).then(({ isDismissed, isConfirmed, isDenied }) => {
      if ((isDismissed || isDenied) && this.onCancel) {
        return this.onCancel();
      } else if (isConfirmed && this.onConfirm) {
        return this.onConfirm();
      } else {
        return true;
      }
    });
  }

  close() {
    Swal.close();
  }
}
