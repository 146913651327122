import React, { useState } from "react";
import classes from "./filter.module.scss";
import { PlusCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { Radio, Tooltip } from "antd";
import {
  ACTIVE_DISEASE_VALUES,
  MODALS,
} from "../../../../../constants/disease";
import SaveDiseaseModal from "../../save-disease-modal";
import DiseaseSelectionBox from "./disease-selection-box";
import classNames from "classnames";
import { insertDisease } from "../../../../../reducers/disease/actions/insert-disease";
import { showErrorFromServerError } from "../../../../../helpers/request";
import { useDispatch } from "react-redux";
import { useSweetAlert } from "../../../../../helpers/useSweetAlert";
import { useHistory } from "react-router";
import { ADMINISTRATION_DISEASE_SUB_PAGES } from "../../../../../constants/pages";
import MainButton from "../../../../../components/main-button";
import { cloneActiveDisease } from "../../../../../reducers/disease/actions/clone-active-disease";
import { propOr } from "ramda";

const Filter = ({
  filters,
  onChangeIsActive,
  diseaseName,
  classIdDefault,
  disabled,
  diseaseId,
}) => {
  const [modal, setModal] = useState(null);
  const dispatch = useDispatch();
  const alert = useSweetAlert();
  const history = useHistory();

  const onSaveDisease = (values) => {
    alert.progress("Adding").fire();
    dispatch(insertDisease(values))
      .then((res) => {
        alert.progress().close();
        alert
          .success("Added Successfully!")
          .fire()
          .then(() => {
            setModal(null);
            if (res) {
              history.push(
                `${ADMINISTRATION_DISEASE_SUB_PAGES.ADMINISTRATION_DISEASE_DETAILS.getLocation(
                  res
                )}`
              );
            }
          });
      })
      .catch(showErrorFromServerError(alert));
  };

  const onCloneActiveDisease = () => {
    alert.progress("Copying").fire();
    dispatch(
      cloneActiveDisease({
        disease_id: diseaseId,
        active_disease: filters.active_disease
          ? ACTIVE_DISEASE_VALUES.inThePastDisease.value
          : ACTIVE_DISEASE_VALUES.activeDisease.value,
      })
    )
      .then((res) => {
        const successArray = propOr([], "success", res);
        const numberIdsSuccess = successArray.filter(
          (item) => item === 1
        ).length;
        alert.progress().close();
        alert
          .success(
            `${numberIdsSuccess} ${
              (numberIdsSuccess <= 1) ? "entry" : "entries"
            } added`)
          .fire()
          .then(() => {
            setModal(null);
            if (res) {
              history.push(
                `${ADMINISTRATION_DISEASE_SUB_PAGES.ADMINISTRATION_DISEASE_DETAILS.getLocation(
                  res
                )}`
              );
            }
          });
      })
      .catch(showErrorFromServerError(alert));
  };

  const onHide = () => {
    setModal(null);
  };

  return (
    <div className={classes.FilterWrap}>
      <div className={classes.Filter}>
        <div className={classes.FirstFilter}>
          <div
            className={classNames(classes.DiseaseBox, {
              [classes.Disabled]: disabled,
            })}
          >
            <div
              className={classes.AddDiseaseIcon}
              onClick={() => setModal(MODALS.saveDiseaseModal)}
            >
              <PlusCircleOutlined />
            </div>
            <Tooltip placement="topLeft" title={diseaseName}>
              <div className={classes.DiseaseName}>{diseaseName}</div>
            </Tooltip>

            <DiseaseSelectionBox classIdDefault={classIdDefault} />
          </div>
        </div>
        <div className={classes.RightWrap}>
          <div></div>
          <div className={classes.CopyButtonWrap}>
            <MainButton
              className={classes.CopyButton}
              onClick={onCloneActiveDisease}
            >
              <CopyOutlined className={classes.CopyIcon} />
              {filters.currentState
                ? "Copy to active disease"
                : "Copy to disease in the past"}
            </MainButton>
          </div>
          <Radio.Group
            value={filters.currentState}
            onChange={onChangeIsActive}
            className={classNames({ [classes.Disabled]: disabled })}
          >
            <Radio value={false} className={classes.CheckBoxItem}>
              Active disease
            </Radio>
            <Radio value={true} className={classes.CheckBoxItem}>
              Disease in the past
            </Radio>
          </Radio.Group>
        </div>
      </div>

      {modal === MODALS.saveDiseaseModal && (
        <SaveDiseaseModal
          onSubmit={onSaveDisease}
          visible={modal === MODALS.saveDiseaseModal}
          onHide={onHide}
          diseaseItem={null}
          currentClass={classIdDefault}
        />
      )}
    </div>
  );
};

export default Filter;
