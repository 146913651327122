import DiseaseService from "../../../services/disease.service";
import * as DiseaseNormalize from "../normalize";

export const GET_DISEASE_LIST_FETCH = "GET_DISEASE_LIST_FETCH";
export const GET_DISEASE_LIST_SUCCESS = "GET_DISEASE_LIST_SUCCESS";
export const GET_DISEASE_LIST_FAILED = "GET_DISEASE_LIST_FAILED";

const diseaseService = new DiseaseService();

export const getDiseaseListByClass = (classId) => {
  return (dispatch) => {
    dispatch(getDiseaseListFetch());
    return diseaseService
      .getDiseaseList({class_id: classId})
      .then((resultList) => {
        const normalized = DiseaseNormalize.getDiseaseList.success(resultList);
        dispatch(getDiseaseListSuccess({...normalized, class_id: classId}));

        return resultList;
      })
      .catch((error) => {
        dispatch(getDiseaseListFailed(error));
        return Promise.reject(error);
      });
  };
};

export const getDiseaseListFetch = () => ({
  type: GET_DISEASE_LIST_FETCH,
});

export const getDiseaseListSuccess = (payload) => ({
  payload,
  type: GET_DISEASE_LIST_SUCCESS,
});

export const getDiseaseListFailed = (list) => ({
  list,
  type: GET_DISEASE_LIST_FAILED,
});
