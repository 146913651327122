import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Disease from "./disease";
import classes from "./pages.module.scss";
import classNames from "classnames";
import { connect, useSelector } from "react-redux";

import RouteWrapper from "../components/route";
import { DISEASE_SUB_PAGES, PAGES } from "../constants/pages";
import DiseaseDetail from "./disease/disease-detail";
import Login from "./login";
import { selectUser } from "../reducers/auth/selectors";
import AdministrationPages from "./administration";
import RegisterPassword from "./register-password";
import ForgotPassword from "./forgot-password";

function Pages() {
  const user = useSelector(selectUser);

  const publicPages = [
    {
      path: PAGES.REGISTER_PASSWORD.path,
      component: RegisterPassword,
      exact: true,
    },
    {
      path: PAGES.FORGOT_PASSWORD.path,
      component: ForgotPassword,
      exact: true,
    },
    {
      path: PAGES.LOGIN.path,
      component: Login,
      exact: true,
    },
  ];

  const privatePages = [
    {
      path: PAGES.DISEASE.path,
      component: Disease,
      exact: true,
    },
    {
      path: DISEASE_SUB_PAGES.DISEASE_DETAIL.path,
      component: DiseaseDetail,
      exact: true,
    },
    {
      path: PAGES.ADMINISTRATION.path,
      component: AdministrationPages,
    },
  ];

  return (
    <div className={classNames(classes.Wrap, "no-select")}>
      <Switch>
        <React.Fragment>
          {publicPages.map((props) => (
            <RouteWrapper key={props.path} user={user} restricted {...props} />
          ))}
          <div className={classNames(classes.PaddingWrap)}>
            {privatePages.map((props) => (
              <RouteWrapper key={props.path} user={user} {...props} />
            ))}
            <Route exact path="/">
              <Redirect to={PAGES.LOGIN.path} />
            </Route>
          </div>
        </React.Fragment>
      </Switch>
    </div>
  );
}

const mapStateToProps = function () {
  return {};
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
