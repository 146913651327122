import { initialState } from "./index";
import { createSelector } from "reselect";
import memoizeOne from "memoize-one";

import { assoc, pathOr, propOr } from "ramda";
import { FILTER_POSITION } from "../../constants/filter";

export const selectDisease = (state) => state.DiseaseReducer || initialState;

export const selectEntities = createSelector(
  selectDisease,
  (diseaseState) => diseaseState.entities
);

export const selectIds = createSelector(
  selectDisease,
  (diseaseState) => diseaseState.ids
);

export const selectDiseaseClass = createSelector(
  selectDisease,
  (diseaseState) => diseaseState.applied_filters.disease_class
);

export const selectCurrentFilters = createSelector(
  selectDisease,
  (diseaseState) => {
    return {
      filters: diseaseState.applied_filters.filters,
      active_disease_state: diseaseState.applied_filters.active_disease_state,
    };
  }
);

export const selectCurrentVerticalFilters = createSelector(
  selectDisease,
  (diseaseState) => {
    return diseaseState.applied_filters.vertical_filters;
  }
);

export const selectCurrentHorizontalFilters = createSelector(
  selectDisease,
  (diseaseState) => {
    return diseaseState.applied_filters.horizontal_filters;
  }
);

export const selectCurrentActiveDiseaseState = createSelector(
  selectDisease,
  (diseaseState) => {
    return diseaseState.applied_filters.active_disease_state;
  }
);

export const selectCurrentDiseaseClasses = createSelector(
  selectEntities,
  selectDiseaseClass,
  (entities, diseaseClass) => {
    return diseaseClass.map((item) => {
      const diseases = propOr([], "diseases", item).map((id) => entities[id]);
      return assoc("diseases", diseases, item);
    });
  }
);

export const selectDiseaseDetails = createSelector(
  selectEntities,
  (entities) => {
    return memoizeOne((diseaseId) => {
      return pathOr({}, [diseaseId, "disease_details"])(entities);
    });
  }
);

export const selectCurrentClass = createSelector(
  selectDisease,
  (diseaseState) => diseaseState.by_classes.current_class
);

export const selectDiseasesByClass = createSelector(
  selectDisease,
  (diseaseState) => diseaseState.by_classes.disease_by_class
);

export const selectAllDiseases = createSelector(
  selectDisease,
  (diseaseState) => diseaseState.entities
);

export const selectCurrentDiseasesByClass = createSelector(
  selectDiseasesByClass,
  selectAllDiseases,
  (diseasesByClass, diseases) => {
    return memoizeOne((classId) => {
      const currentDiseaseIds = propOr([], classId, diseasesByClass);
      return currentDiseaseIds.map((id) => diseases[id]);
    });
  }
);
