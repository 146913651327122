import { FILTERING_DISEASE_SUCCESS } from "./actions/filtering-disease";
import {
  lensProp,
  mergeDeepLeft,
  set,
  pathOr,
  pipe,
  prop,
  uniq,
  assocPath,
  concat,
  lensPath,
} from "ramda";
import { GET_DISEASE_DETAIL_SUCCESS } from "./actions/get-disease-detail";
import { GET_DISEASE_LIST_SUCCESS } from "./actions/get-disease-list-by-class";

export const initialState = {
  entities: {},
  ids: [],
  applied_filters: {
    vertical_filters: [],
    horizontal_filters: [],
    active_disease_state: null,
    disease_class: [],
  },
  by_classes: {
    disease_by_class: {},
  },
};

const entities = (state = initialState.entities, action) => {
  switch (action.type) {
    case GET_DISEASE_LIST_SUCCESS:
    case FILTERING_DISEASE_SUCCESS: {
      return mergeDeepLeft(
        pathOr({}, ["payload", "entities", "diseases"], action),
        state
      );
    }

    case GET_DISEASE_DETAIL_SUCCESS: {
      const diseaseDetail = pathOr({}, ["payload", "disease_results"], action);
      const diseaseId = pathOr(
        {},
        ["payload", "disease_results", "disease_id"],
        action
      );

      return pipe(assocPath([diseaseId, "disease_details"], diseaseDetail))(
        state
      );
    }

    default:
      return state;
  }
};

const ids = (state = initialState.ids, action) => {
  switch (action.type) {
    case GET_DISEASE_LIST_SUCCESS:
    case FILTERING_DISEASE_SUCCESS: {
      return pipe(
        concat(pathOr([], ["payload", "result"], action)),
        uniq
      )(state);
    }

    default:
      return state;
  }
};

const applied_filters = (state = initialState.applied_filters, action) => {
  switch (action.type) {
    case FILTERING_DISEASE_SUCCESS: {
      return pipe(
        set(
          lensProp("vertical_filters"),
          pathOr([], ["payload", "applied_vertical_filters"], action)
        ),
        set(
          lensProp("horizontal_filters"),
          pathOr([], ["payload", "applied_horizontal_filters"], action)
        ),
        set(
          lensProp("active_disease_state"),
          pathOr([], ["payload", "active_disease_state"], action)
        ),
        set(
          lensProp("disease_class"),
          pathOr([], ["payload", "diseaseClass"], action)
        )
      )(state);
    }

    default:
      return state;
  }
};

const by_classes = (state = initialState.by_classes, action) => {
  switch (action.type) {
    case GET_DISEASE_LIST_SUCCESS: {
      const class_id = pathOr(null, ["payload", "class_id"], action);
      return class_id
        ? pipe(
            set(
              lensPath(["disease_by_class", class_id]),
              pathOr([], ["payload", "result"], action)
            )
          )(state)
        : state;
    }

    default:
      return state;
  }
};

export default (state, action) => {
  return {
    entities: entities(prop("entities", state), action),
    ids: ids(prop("ids", state), action),
    applied_filters: applied_filters(prop("applied_filters", state), action),
    by_classes: by_classes(prop("by_classes", state), action),
  };
};
