import { concat, propOr } from "ramda";
import { FILTER_POSITION } from "../constants/filter";

export default class FilterTransform {
  getFilterList() {
    return {
      success(response) {
        const horizontalFilters = propOr(
          [],
          "horizontal_filters",
          response
        ).map((item) => {
          return { ...item, position: FILTER_POSITION.horizontal };
        });

        const verticalFilters = propOr([], "vertical_filters", response).map(
          (item) => {
            return { ...item, position: FILTER_POSITION.vertical };
          }
        );

        return concat(verticalFilters, horizontalFilters);
      },
    };
  }
}
