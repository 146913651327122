import HttpService from "./http.service";
import { BACK_END_URL } from "../environments";
import FilterTransform from "./filter.transform";

export default class FilterService {
  constructor() {
    this.http = new HttpService();
    this.transform = new FilterTransform();
  }

  getFilterList() {
    return this.http
      .get(`${BACK_END_URL}/filter_list.php`)
      .then((res) => this.transform.getFilterList().success(res));
  }

  insertFilter(data) {
    return this.http.post(`${BACK_END_URL}/insert_filter.php`, data);
  }

  updateFilter(data) {
    return this.http.post(`${BACK_END_URL}/update_filter.php`, data);
  }
}
