import ObjectService from "../../../services/object.service";
import { getDiseaseListByClass } from "./get-disease-list-by-class";

export const DELETE_DISEASE_FETCH = "DELETE_DISEASE_FETCH";
export const DELETE_DISEASE_SUCCESS = "DELETE_DISEASE_SUCCESS";
export const DELETE_DISEASE_FAILED = "DELETE_DISEASE_FAILED";

const objectService = new ObjectService();

export const deleteDisease = (id, currentClass) => {
  return (dispatch) => {
    dispatch(deleteDiseaseFetch());
    return objectService
      .deleteObject({ disease_id: id })
      .then(() => {
        dispatch(deleteDiseaseSuccess());
        dispatch(getDiseaseListByClass(currentClass));
      })
      .catch((error) => {
        dispatch(deleteDiseaseFailed(error));
        return Promise.reject(error);
      });
  };
};

export const deleteDiseaseFetch = () => ({
  type: DELETE_DISEASE_FETCH,
});

export const deleteDiseaseSuccess = (payload) => ({
  payload,
  type: DELETE_DISEASE_SUCCESS,
});

export const deleteDiseaseFailed = (list) => ({
  list,
  type: DELETE_DISEASE_FAILED,
});
