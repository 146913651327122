import DiseaseService from "../../../services/disease.service";
import {getDiseaseDetail} from "./get-disease-detail";

export const INSERT_SIGNIFICANCE_FETCH = "INSERT_SIGNIFICANCE_FETCH";
export const INSERT_SIGNIFICANCE_SUCCESS = "INSERT_SIGNIFICANCE_SUCCESS";
export const INSERT_SIGNIFICANCE_FAILED = "INSERT_SIGNIFICANCE_FAILED";

const diseaseService = new DiseaseService();

export const insertSignificance = (data) => {
  return (dispatch) => {
    dispatch(insertSignificanceFetch());
    return diseaseService
      .insertSignificance({
        disease_id: data.disease_id,
        filter_id: data.filter_id,
        active_disease: data.active_disease,
        severeness: data.severeness,
        score: data.score,
        description: data.description
      })
      .then((res) => {
        dispatch(insertSignificanceSuccess());
        dispatch(getDiseaseDetail(data.disease_id));
        return res;
      })
      .catch((error) => {
        dispatch(insertSignificanceFailed(error));
        return Promise.reject(error);
      });
  };
};

export const insertSignificanceFetch = () => ({
  type: INSERT_SIGNIFICANCE_FETCH,
});

export const insertSignificanceSuccess = (payload) => ({
  payload,
  type: INSERT_SIGNIFICANCE_SUCCESS,
});

export const insertSignificanceFailed = (list) => ({
  list,
  type: INSERT_SIGNIFICANCE_FAILED,
});
