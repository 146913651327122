import React, { useMemo, useState } from "react";
import classes from "./administration.module.scss";
import { Avatar, Breadcrumb, Menu } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { EYE_MENU } from "../../constants/images";
import User from "./user";
import {
  ADMINISTRATION_ITEMS,
  DISEASE_SUB_ITEMS,
} from "../../constants/administration";
import { values } from "ramda";
import Class from "./class";
import Filter from "./filter";
import Disease from "./disease";
import {
  ADMINISTRATION_DISEASE_SUB_PAGES,
  ADMINISTRATION_SUB_PAGES,
} from "../../constants/pages";
import { Link, Route, Switch, useLocation, matchPath } from "react-router-dom";
import AdministrationDiseaseDetail from "./disease/administration-disease-detail";
import { useHistory } from "react-router";
import classNames from "classnames";
import UserProfileAction from "./user-profile-action";

const AdministrationPages = () => {
  const location = useLocation();
  const history = useHistory();
  const isMatchUsers = matchPath(location.pathname, {
    exact: true,
    path: ADMINISTRATION_SUB_PAGES.USERS_MANAGEMENT.path,
  });

  const isMatchClass = matchPath(location.pathname, {
    exact: true,
    path: ADMINISTRATION_SUB_PAGES.CLASS_MANAGEMENT.path,
  });

  const isMatchDisease = matchPath(location.pathname, {
    exact: true,
    path: ADMINISTRATION_SUB_PAGES.DISEASE_MANAGEMENT.path,
  });

  const isMatchFilter = matchPath(location.pathname, {
    exact: true,
    path: ADMINISTRATION_SUB_PAGES.FILTER_MANAGEMENT.path,
  });

  const isMatchDiseaseDetail = matchPath(location.pathname, {
    exact: true,
    path: ADMINISTRATION_DISEASE_SUB_PAGES.ADMINISTRATION_DISEASE_DETAILS.path,
  });

  const currentMenuItem = useMemo(() => {
    if (isMatchUsers) {
      return [ADMINISTRATION_ITEMS.users];
    }

    if (isMatchClass) {
      return [ADMINISTRATION_ITEMS.class];
    }

    if (isMatchDisease) {
      return [ADMINISTRATION_ITEMS.disease];
    }

    if (isMatchFilter) {
      return [ADMINISTRATION_ITEMS.filter];
    }

    if (isMatchDiseaseDetail) {
      return [ADMINISTRATION_ITEMS.disease, DISEASE_SUB_ITEMS.diseaseDetails];
    }

    history.push(ADMINISTRATION_ITEMS.users.fullPath);
    return [];
  }, [
    isMatchClass,
    isMatchDisease,
    isMatchFilter,
    isMatchUsers,
    isMatchDiseaseDetail,
  ]);

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={classes.AdministrationWrap}>
      <div className={classes.Header}>
        <div className={classes.LeftWrap}>
          <div className={classes.MenuButton}>
            <img src={EYE_MENU} />
            <div className={classes.ToggleIcon} onClick={toggleCollapsed}>
              {collapsed ? <CaretDownOutlined /> : <CaretUpOutlined />}
            </div>
          </div>
          <div className={classes.Breadcrumb}>
            <Breadcrumb>
              <Breadcrumb.Item>Administration</Breadcrumb.Item>
              {currentMenuItem.map((item, index) => {
                if (index === currentMenuItem.length - 1) {
                  return <Breadcrumb.Item>{item.name}</Breadcrumb.Item>;
                } else {
                  return (
                    <Breadcrumb.Item>
                      <Link to={item.fullPath}>{item.name}</Link>
                    </Breadcrumb.Item>
                  );
                }
              })}
            </Breadcrumb>
          </div>
        </div>
        <UserProfileAction/>
      </div>

      <div className={classes.Menu}>
        {!collapsed && (
          <Menu
            mode="inline"
            theme="light"
            inlineCollapsed={collapsed}
            selectedKeys={[currentMenuItem[0].key]}
          >
            {values(ADMINISTRATION_ITEMS).map((item) => (
              <Menu.Item key={item.key}>
                <Link to={item.fullPath}>
                  <div className={classes.MenuIcon}>{item.icon}</div>
                  <div className={classes.MenuText}>{item.name}</div>
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        )}
      </div>
      <div
        className={classNames(classes.ContentWrap, {
          [classes.ContentWrapNoCollapsed]: collapsed,
        })}
      >
        <Switch>
          <Route exact path={ADMINISTRATION_SUB_PAGES.USERS_MANAGEMENT.path}>
            <User />
          </Route>

          <Route exact path={ADMINISTRATION_SUB_PAGES.CLASS_MANAGEMENT.path}>
            <Class />
          </Route>

          <Route exact path={ADMINISTRATION_SUB_PAGES.DISEASE_MANAGEMENT.path}>
            <Disease />
          </Route>

          <Route
            exact
            path={
              ADMINISTRATION_DISEASE_SUB_PAGES.ADMINISTRATION_DISEASE_DETAILS
                .path
            }
          >
            <AdministrationDiseaseDetail />
          </Route>

          <Route exact path={ADMINISTRATION_SUB_PAGES.FILTER_MANAGEMENT.path}>
            <Filter />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default AdministrationPages;
