import React, { useMemo } from "react";
import classes from "./filter-active-state.module.scss";
import Checkbox from "antd/es/checkbox/Checkbox";
import classNames from "classnames";

const FilterActiveState = ({ isLoading, handleChangeFilterActiveState, currentActiveState }) => {
  const onChangeCheckBox = (isActive) => {
    let active_disease;
    switch (Number(currentActiveState)) {
      case 1: {
        if (isActive) {
          active_disease = 2;
        } else {
          active_disease = 3;
        }
        break;
      }

      case 2: {
        if (isActive) {
          active_disease = 3;
        } else {
          active_disease = 1;
        }
        break;
      }

      case 3: {
        if (isActive) {
          active_disease = 2;
        } else {
          active_disease = 1;
        }
        break;
      }

      default: {
        if (isActive) {
          active_disease = 1;
        } else {
          active_disease = 2;
        }
        break;
      }
    }

    if (!isLoading) {
      handleChangeFilterActiveState(
        active_disease
      );
    }
  };

  const isActiveChecked = useMemo(() => {
    return (
      Number(currentActiveState) === 1 ||
      Number(currentActiveState) === 3
    );
  }, [currentActiveState]);

  const isInThePastChecked = useMemo(() => {
    return (
      Number(currentActiveState) === 2 ||
      Number(currentActiveState) === 3
    );
  }, [currentActiveState]);

  return (
    <div className={classes.FilterWrap}>
      <div
        className={classNames(classes.CheckBoxWrap, {
          [classes.IsProcess]: isLoading,
        })}
      >
        <Checkbox
          className={classes.CheckBoxItem}
          checked={isActiveChecked}
          onChange={() => onChangeCheckBox(true)}
        >
          Active disease
        </Checkbox>
        <Checkbox
          className={classes.CheckBoxItem}
          checked={isInThePastChecked}
          onChange={() => onChangeCheckBox(false)}
        >
          Disease in the past
        </Checkbox>
      </div>
    </div>
  );
};

export default FilterActiveState;
