import {
  ClusterOutlined,
  BugOutlined,
  UserOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import {PAGES} from "./pages";

export const ADMINISTRATION_ITEMS = {
  users: {
    key: "users",
    path: "path",
    fullPath: `${PAGES.ADMINISTRATION.path}/users`,
    name: "Users",
    icon: <UserOutlined />,
  },
  class: {
    key: "class",
    path: "classes",
    fullPath: `${PAGES.ADMINISTRATION.path}/classes`,
    name: "Classes",
    icon: <ClusterOutlined />,
  },
  disease: {
    key: "disease",
    path: "diseases",
    fullPath: `${PAGES.ADMINISTRATION.path}/diseases`,
    name: "Diseases",
    icon: <BugOutlined />,
  },
  filter: {
    key: "filter",
    path: "filters",
    fullPath: `${PAGES.ADMINISTRATION.path}/filters`,
    name: "Filters",
    icon: <FilterOutlined />,
  },
};

export const DISEASE_SUB_ITEMS = {
  diseaseDetails: {
    key: "disease_details",
    name: "Disease details",
  },
};
