import HttpService from "./http.service";
import { BACK_END_URL } from "../environments";

export default class AuthService {
  constructor() {
    this.http = new HttpService();
  }

  login(data) {
    return this.http.post(`${BACK_END_URL}/login.php`, data);
  }

  resetPassword(data) {
    return this.http.post(`${BACK_END_URL}/resetpw.php`, data);
  }

  forgotPassword(data) {
    return this.http.post(`${BACK_END_URL}/forgotpw.php`, data);
  }
}
