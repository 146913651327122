import ObjectService from "../../../services/object.service";
import { getClassList } from "./get-class-list";

export const SORT_SWITCH_CLASS_FETCH = "SORT_SWITCH_CLASS_FETCH";
export const SORT_SWITCH_CLASS_SUCCESS = "SORT_SWITCH_CLASS_SUCCESS";
export const SORT_SWITCH_CLASS_FAILED = "SORT_SWITCH_CLASS_FAILED";

const objectService = new ObjectService();

export const sortSwitchClass = (id, upperId) => {
  return (dispatch) => {
    dispatch(sortSwitchClassFetch());
    return objectService
      .sortSwitchObject({ class_id: id, upper_id: upperId })
      .then(() => {
        dispatch(sortSwitchClassSuccess());
        dispatch(getClassList());
      })
      .catch((error) => {
        dispatch(sortSwitchClassFailed(error));
        return Promise.reject(error);
      });
  };
};

export const sortSwitchClassFetch = () => ({
  type: SORT_SWITCH_CLASS_FETCH,
});

export const sortSwitchClassSuccess = (payload) => ({
  payload,
  type: SORT_SWITCH_CLASS_SUCCESS,
});

export const sortSwitchClassFailed = (list) => ({
  list,
  type: SORT_SWITCH_CLASS_FAILED,
});
