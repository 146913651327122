import React from "react";
import { Modal } from "antd";
import MainButton from "../../../../components/main-button";
import classes from "./save-user-modal.module.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "../../../../components/form-items/input";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  always,
  applySpec,
  defaultTo,
  either,
  mergeLeft,
  pipe,
  prop,
} from "ramda";
import withLoadData from "./with-load-data";

const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email("Invalid email format").required(""),
});

const SaveUserModal = ({ visible, onSubmit, onHide, user, defaultValues }) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  return (
    <Modal
      title={user ? "Edit User" : "Create User"}
      visible={visible}
      onCancel={onHide}
      footer={null}
      className={classes.FormModal}
      width={500}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.Row}>
          <label className={classes.Label}>First name:</label>
          <Input control={control} name="first_name" />
        </div>
        <div className={classes.Row}>
          <label className={classes.Label}>Last name:</label>
          <Input control={control} name="last_name" />
        </div>
        <div className={classes.Row}>
          <label className={classes.Label}>Email:</label>
          <Input control={control} name="email" />
          <div className={classes.Error}>{errors.email?.message}</div>
        </div>
        <div className={classes.Footer}>
          <MainButton type="submit" disabled={isSubmitting || !isDirty}>
            Save
          </MainButton>
        </div>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state, props) => {
  const defaultValues = applySpec({
    first_name: either(prop("first_name"), always("")),
    last_name: either(prop("last_name"), always("")),
    email: either(prop("email"), always("")),
  })(props.user);

  return {
    defaultValues: pipe(defaultTo({}), mergeLeft(defaultValues))(props.user),
  };
};

export default compose(
  withLoadData,
  connect(mapStateToProps, undefined)
)(SaveUserModal);
