import React from "react";
import classes from "./register-password-form.module.scss";
import { connect } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Input from "../../../../components/form-items/input";
import { Card } from "antd";
import { LOGO } from "../../../../constants/images";
import MainButton from "../../../../components/main-button";

const schema = yup.object().shape({
  password: yup.string().required(),
  confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must be matched')
});

function RegisterPasswordForm({ onSubmit }) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, errors },
  } = useForm({ resolver: yupResolver(schema) });

  return (
    <div className={classes.RegisterPasswordForm}>
      <Card className={classes.RegisterPasswordFormWrap} bodyStyle={{ width: "100%" }}>
        <div className={classes.Form}>
          <div className={classes.LogoImage}>
            <img src={LOGO} />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.Row}>
              <Input
                className={classes.InputCustom}
                control={control}
                name="password"
                type="password"
                placeholder="New password"
              />
            </div>
            <div className={classes.Row}>
              <Input
                className={classes.InputCustom}
                control={control}
                name="confirm_password"
                type="password"
                placeholder="Confirm new password"
              />
            </div>
            <div className={classes.Error}>{errors.confirm_password?.message}</div>
            <div className={classes.ButtonWrap}>
              <MainButton
                className={classes.ButtonCustom}
                type="submit"
                disabled={isSubmitting || !isDirty}
              >
                Set password
              </MainButton>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
}
const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, null)(RegisterPasswordForm);
