import React from "react";
import { useController } from "react-hook-form";
import classes from "./checkbox-group.module.scss";
import classNames from "classnames";

import { Checkbox } from 'antd';

const AntCheckboxGroup = Checkbox.Group;

const CheckboxGroup = ({
  control,
  name,
  defaultValue,
  className,
  options,
  children,
  ...props
}) => {
  const { field } = useController({
    control,
    name,
    defaultValue,
  });

  return (
    <AntCheckboxGroup
      options={options}
      className={classNames(classes.SelectInput, className)}
      {...field}
      {...props}
    />
  );
};

export default CheckboxGroup;
