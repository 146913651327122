export const PAGES = {
   LOGIN: {
      id: "LOGIN",
      label: "Login",
      path: "/login",
      location: "/login",
   },
   REGISTER_PASSWORD: {
      id: "REGISTER_PASSWORD",
      label: "Register password",
      path: "/password/:hash",
      location: "/password/:hash",
      getLocation: (hash) => `/password/${hash}`,
   },
   FORGOT_PASSWORD: {
      id: "FORGOT_PASSWORD",
      label: "Forgot password",
      path: "/forgot-password",
      location: "/forgot-password",
      getLocation: () => `/forgot-password`,
   },
   DISEASE: {
      id: "DISEASE",
      label: "Disease",
      path: "/disease",
      location: "/disease",
   },
   ADMINISTRATION: {
      id: "ADMINISTRATION",
      label: "Administration",
      path: "/administration",
      location: "/administration",
   },
};

export const DISEASE_SUB_PAGES = {
   DISEASE_DETAIL: {
      id: "DISEASE_DETAIL",
      label: "Disease detail",
      path: `${PAGES.DISEASE.path}/:id`,
      getLocation: (id) => `${PAGES.DISEASE.path}/disease/${id}`,
   },
};

export const ADMINISTRATION_SUB_PAGES = {
   USERS_MANAGEMENT: {
      id: "USERS_MANAGEMENT",
      label: "User management",
      path: `${PAGES.ADMINISTRATION.path}/users`,
      location: `${PAGES.ADMINISTRATION.path}/users`,
   },
   DISEASE_MANAGEMENT: {
      id: "DISEASE_MANAGEMENT",
      label: "Disease management",
      path: `${PAGES.ADMINISTRATION.path}/diseases`,
      location: `${PAGES.ADMINISTRATION.path}/diseases`,
   },
   CLASS_MANAGEMENT: {
      id: "CLASS_MANAGEMENT",
      label: "Classes management",
      path: `${PAGES.ADMINISTRATION.path}/classes`,
      location: `${PAGES.ADMINISTRATION.path}/classes`,
   },
   FILTER_MANAGEMENT: {
      id: "FILTER_MANAGEMENT",
      label: "Filter management",
      path: `${PAGES.ADMINISTRATION.path}/filters`,
      location: `${PAGES.ADMINISTRATION.path}/filters`,
   },

};

export const ADMINISTRATION_DISEASE_SUB_PAGES = {
   ADMINISTRATION_DISEASE_DETAILS: {
      id: "ADMINISTRATION_DISEASE_DETAILS",
      label: "Disease details",
      path: `${PAGES.ADMINISTRATION.path}/diseases/:id`,
      getLocation: (id) => `${PAGES.ADMINISTRATION.path}/diseases/${id}`,
   },
};