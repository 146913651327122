import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showErrorFromServerError } from "../../../helpers/request";
import { useSweetAlert } from "../../../helpers/useSweetAlert";
import classes from "./filter.module.scss";
import MainButton from "../../../components/main-button";
import { PlusOutlined, UserAddOutlined } from "@ant-design/icons";
import { FILTER_POSITION, MODALS } from "../../../constants/filter";
import { getFilterList } from "../../../reducers/filter/actions/get-filter-list";
import { sortSwitchFilter } from "../../../reducers/filter/actions/sort-switch-class";
import { deleteFilter } from "../../../reducers/filter/actions/delete-filter";
import SaveFilterModal from "./save-filter-modal";
import FilterList from "./filter-list";
import { insertFilter } from "../../../reducers/filter/actions/insert-filter";
import { editFilter } from "../../../reducers/filter/actions/edit-filter";
import { sort } from "ramda";
import { sortFunc } from "../../../helpers/common";
import { selectFilters } from "../../../reducers/filter/selectors";
import { Table, Tabs } from "antd";

const Filter = () => {
  const TABS = {
    vertical: { id: FILTER_POSITION.vertical, label: "Vertical" },
    horizontal: { id: FILTER_POSITION.horizontal, label: "Horizontal" },
  };

  const data = useSelector(selectFilters);
  const alert = useSweetAlert();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentTab, setCurrentTab] = useState(TABS.vertical.id);

  const verticalFilterList = useMemo(() => {
    return sort(
      sortFunc,
      data.filter((item) => item.position === FILTER_POSITION.vertical)
    );
  }, [data]);

  const horizontalFilterList = useMemo(() => {
    return sort(
      sortFunc,
      data.filter((item) => item.position === FILTER_POSITION.horizontal)
    );
  }, [data]);

  const getListFilter = () => {
    setIsLoading(true);
    dispatch(getFilterList())
      .catch(showErrorFromServerError(alert))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onEdit = (item) => {
    setCurrentItem(item);
    setModal(MODALS.saveFilterModal);
  };

  const onHide = () => {
    setModal(null);
    setCurrentItem(null);
  };

  const onCreateFilter = () => {
    setCurrentItem({horizontal_vertical: currentTab });
    setModal(MODALS.saveFilterModal);
  }

  const onSaveFilter = (data) => {
    if (!data.id) {
      alert.progress("Adding").fire();
      dispatch(insertFilter(data))
        .then(() => {
          alert.progress().close();
          alert
            .success("Added Successfully!")
            .fire()
            .then(() => {
              setModal(null);
            });
        })
        .catch(showErrorFromServerError(alert));
    } else {
      alert.progress("Editing").fire();
      dispatch(editFilter(data))
        .then(() => {
          alert.progress().close();
          alert
            .success("Edited Successfully!")
            .fire()
            .then(() => {
              setModal(null);
            });
        })
        .catch(showErrorFromServerError(alert));
    }
  };

  const handleSwitch = (id, upperId) => {
    alert.progress("Processing").fire();
    dispatch(sortSwitchFilter(id, upperId))
      .then(() => {
        alert.progress().close();
        alert
          .success("Updated Successfully!")
          .fire()
          .then(() => {
            setModal(null);
          });
      })
      .catch(showErrorFromServerError(alert));
  };

  const handleDelete = (data) => {
    alert.progress("Deleting").fire();
    dispatch(deleteFilter(data.filter_id))
      .then(() => {
        alert.progress().close();
        alert
          .success("Deleted Successfully!")
          .fire()
          .then(() => {
            setModal(null);
          });
      })
      .catch(showErrorFromServerError(alert));
  };

  useEffect(() => {
    getListFilter();
  }, []);

  return (
    <div className={classes.Container}>
      <Tabs
        className={classes.Tab}
        onChange={(key) => setCurrentTab(key)}
        tabBarExtraContent={
          <MainButton
            className={classes.NewUserButton}
            onClick={onCreateFilter}
          >
            <PlusOutlined /> Add{" "}
            {currentTab === TABS.vertical.id ? "Vertical" : "Horizontal"} Filter
          </MainButton>
        }
        defaultActiveKey={currentTab}
        type="card"
        items={[
          {
            label: TABS.vertical.label,
            key: TABS.vertical.id,
            children: (
              <FilterList
                onEdit={onEdit}
                onDelete={handleDelete}
                handleSwitch={handleSwitch}
                isLoading={isLoading}
                filterList={verticalFilterList}
              />
            ),
          },
          {
            label: TABS.horizontal.label,
            key: TABS.horizontal.id,
            children: (
              <FilterList
                onEdit={onEdit}
                onDelete={handleDelete}
                handleSwitch={handleSwitch}
                isLoading={isLoading}
                filterList={horizontalFilterList}
              />
            ),
          },
        ]}
      />

      {modal === MODALS.saveFilterModal && (
        <SaveFilterModal
          onSubmit={onSaveFilter}
          visible={modal === MODALS.saveFilterModal}
          onHide={onHide}
          filterItem={currentItem}
        />
      )}
    </div>
  );
};

export default Filter;
