import DiseaseService from "../../../services/disease.service";
import {selectFilters} from "../../filter/selectors";
import {getFilterList} from "../../filter/actions/get-filter-list";

export const GET_DISEASE_DETAIL_FETCH = "GET_DISEASE_DETAIL_FETCH";
export const GET_DISEASE_DETAIL_SUCCESS = "GET_DISEASE_DETAIL_SUCCESS";
export const GET_DISEASE_DETAIL_FAILED = "GET_DISEASE_DETAIL_FAILED";

const diseaseService = new DiseaseService();

export const getDiseaseDetail = (diseaseId) => {
  return (dispatch, getState) => {
    const filters = selectFilters(getState());
    if (!filters.length) {
      dispatch(getFilterList());
    }

    dispatch(getDiseaseDetailFetch());
    return diseaseService
      .getDiseaseDetail({disease_id: diseaseId})
      .then((resultList) => {
        dispatch(getDiseaseDetailSuccess({
          ...resultList
        }));

        return resultList;
      })
      .catch((error) => {
        dispatch(getDiseaseDetailFailed(error));
        return Promise.reject(error);
      });
  };
};

export const getDiseaseDetailFetch = () => ({
  type: GET_DISEASE_DETAIL_FETCH,
});

export const getDiseaseDetailSuccess = (payload) => ({
  payload,
  type: GET_DISEASE_DETAIL_SUCCESS,
});

export const getDiseaseDetailFailed = (list) => ({
  list,
  type: GET_DISEASE_DETAIL_FAILED,
});
