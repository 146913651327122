import AuthService from "../../../services/auth.service";

export const SET_PASSWORD_FETCH = "SET_PASSWORD_FETCH";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILED = "SET_PASSWORD_FAILED";

const authService = new AuthService();

export const setPassword = (data) => {
  return (dispatch) => {
    dispatch(SetPasswordFetch());
    return authService
      .resetPassword(data)
      .then(() => {
        dispatch(SetPasswordSuccess());
      })
      .catch((error) => {
        dispatch(SetPasswordFailed());
        return Promise.reject(error);
      });
  };
};

export const SetPasswordFetch = (user) => ({
  user,
  type: SET_PASSWORD_FETCH,
});

export const SetPasswordSuccess = (user) => ({
  user,
  type: SET_PASSWORD_SUCCESS,
});

export const SetPasswordFailed = (user) => ({
  user,
  type: SET_PASSWORD_FAILED,
});
