import React from "react";
import classes from "./user-profile-action.module.scss";
import { Avatar, Popover } from "antd";
import { useDispatch } from "react-redux";
import { Logout } from "../../../reducers/user/actions/logout";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";

const UserProfileAction = () => {
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(Logout());
  };

  return (
    <div className={classes.ProfileWrap}>
      <Popover
        content={
          <div className={classes.LogoutText} onClick={onLogout}>
            <LogoutOutlined /> Logout
          </div>
        }
        trigger="click"
        placement="bottom"
      >
        <Avatar
          style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
          size={{ xs: 24, sm: 32, md: 40, lg: 50, xl: 50, xxl: 50 }}
        >
          <UserOutlined />
        </Avatar>
      </Popover>
    </div>
  );
};

export default UserProfileAction;
