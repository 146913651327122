import ObjectService from "../../../services/object.service";
import {getUserList} from "./get-user-list";

export const DELETE_USER_FETCH = "DELETE_USER_FETCH";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

const objectService = new ObjectService();

export const deleteUser = (id) => {
  return (dispatch) => {
    dispatch(deleteUserFetch());
    return objectService
      .deleteObject({ user_id: id })
      .then(() => {
        dispatch(deleteUserSuccess());
        dispatch(getUserList());
      })
      .catch((error) => {
        dispatch(deleteUserFailed(error));
        return Promise.reject(error);
      });
  };
};

export const deleteUserFetch = () => ({
  type: DELETE_USER_FETCH,
});

export const deleteUserSuccess = (payload) => ({
  payload,
  type: DELETE_USER_SUCCESS,
});

export const deleteUserFailed = (list) => ({
  list,
  type: DELETE_USER_FAILED,
});
