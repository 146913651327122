import Axios from "axios";
// import { LogoutFetch } from "reducers/user/actions/logout";
import {always, cond, has, path, pathOr} from "ramda";
import {LogoutFetch} from "../reducers/user/actions/logout";

const Interceptor = (store) => {
  Axios.interceptors.response.use(
    (next) => {
      checkStatus(next.status, store);
      return Promise.resolve(next);
    },
    (error) => {
      const statusCode = path(["response", "status"], error);
      const errorData = cond([
        [has('response'), pathOr({}, ['response', 'data'])],
        [has('request'), pathOr({}, ['request'])],
        [always(true), always(error)]
      ])(error);

      if (statusCode === 401) {
        store.dispatch(LogoutFetch());
        console.log(1);
        localStorage.removeItem("user");

        // TODO: Use history instead
        setTimeout(() => {
          window.location = "/login";
        });
      }

      return Promise.reject(errorData);
    }
  );
};

const checkStatus = (status, store) => {
  if (status === 302 || status === 401) {
    store.dispatch(LogoutFetch());
  }
};

export default Interceptor;
