import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import classes from "./login.module.scss";
import { useSweetAlert } from "../../helpers/useSweetAlert";

import LoginForm from "./sections/login-form";

import { Login as LoginAction } from "../../reducers/auth/actions/login";
import { PAGES } from "../../constants/pages";
import { selectUser } from "../../reducers/auth/selectors";

function Login() {
  const alert = useSweetAlert();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [isShowForgotLink, setIsShowForgotLink] = useState(false);

  const submitForm = (data) => {
    alert.progress("Processing").fire();
    dispatch(LoginAction(data))
      .then((result) => {
        alert.progress().close();
        if (result.success === 0) {
          setIsShowForgotLink(true);
        }
      })
      .catch((error) => {
        setIsShowForgotLink(true);
        alert.progress().close();
        alert
          .error("Username and/or password are incorrect. Please, try again")
          .fire();
      });
  };

  const userExist = user && user.id;

  return (
    <div className={classes.Background}>
      {userExist && <Redirect to={PAGES.DISEASE.location} />}
      <LoginForm onSubmit={submitForm} isShowForgotLink={isShowForgotLink} />
    </div>
  );
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
