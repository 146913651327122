import DiseaseService from "../../../services/disease.service";
import { getDiseaseListByClass } from "./get-disease-list-by-class";

export const EDIT_DISEASE_FETCH = "EDIT_DISEASE_FETCH";
export const EDIT_DISEASE_SUCCESS = "EDIT_DISEASE_SUCCESS";
export const EDIT_DISEASE_FAILED = "EDIT_DISEASE_FAILED";

const diseaseService = new DiseaseService();

export const editDisease = (data) => {
  return (dispatch) => {
    dispatch(editDiseaseFetch());
    return diseaseService
      .updateDisease({
        id: data.id,
        name: data.name,
        class_id: data.class_id,
        stages: data.stages
      })
      .then(() => {
        dispatch(editDiseaseSuccess());
        dispatch(getDiseaseListByClass(data.class_id));
      })
      .catch((error) => {
        dispatch(editDiseaseFailed(error));
        return Promise.reject(error);
      });
  };
};

export const editDiseaseFetch = () => ({
  type: EDIT_DISEASE_FETCH,
});

export const editDiseaseSuccess = (payload) => ({
  payload,
  type: EDIT_DISEASE_SUCCESS,
});

export const editDiseaseFailed = (list) => ({
  list,
  type: EDIT_DISEASE_FAILED,
});
