import React from "react";
import { Table, Space, Popconfirm } from "antd";
import { useSelector } from "react-redux";
import { selectUsers } from "../../../../reducers/user/selectors";
import { DeleteOutlined } from "@ant-design/icons";
import classes from "./user-list.module.scss";

const UserList = ({ isLoading, onDelete }) => {
  const data = useSelector(selectUsers);

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (_, record) => (
        <Space size="middle">
          {record.first_name + " " + record.last_name}
        </Space>
      ),
    },
    {
      title: "Date Added",
      key: "added_date",
      render: (_, record) => <Space size="middle">{record.added_at}</Space>,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "150px",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            placement="bottom"
            title="Are you sure to delete this user?"
            onConfirm={() => onDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined className={classes.DeleteIcon} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        scroll={{ x: 800, y: "calc(100vh - 250px)" }}
        bordered
        columns={columns}
        dataSource={data}
        loading={isLoading}
        pagination={false}
      />
    </div>
  );
};

export default UserList;
