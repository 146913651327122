import {
  modify,
  map,
  pipe,
  propOr,
  values,
  keys,
  mergeAll,
  lensProp,
  over,
  lensPath,
  pluck,
  tap,
  clone,
  assocPath, pathOr, sort,
} from "ramda";

export default class DiseaseTransform {
  filteringDisease() {
    return {
      success(response) {
        const diseaseClass = pipe(
          propOr({}, "disease_class"),
          values,
          tap(console.log),
          map(modify("diseases", pipe(keys)))
        )(response);

        const diseases = pipe(
          clone,
          propOr({}, "disease_class"),
          values,
          map(propOr({}, "diseases")),
          mergeAll
        )(response);

        return {
          active_disease_state: response.active_disease_state,
          applied_vertical_filters: values(response.applied_vertical_filters),
          applied_horizontal_filters: values(response.applied_horizontal_filters),
          diseaseClass: diseaseClass,
          diseases: values(diseases),
        };
      },
    };
  }

  getDiseaseDetail() {
    return {
      success(response) {

        const sortFn = (a, b) => {
          return Number(a) - Number(b);
        }

        const active_severeness = pipe(
          pathOr({}, ["disease_results", "active_disease_severeness"]),
          values,
          pluck('severeness'),
          sort(sortFn)
        )(response);

        const in_past_severeness = pipe(
          pathOr({}, ["disease_results", "in_past_disease_severeness"]),
          values,
          pluck('severeness'),
          sort(sortFn)
        )(response);

        return pipe(
          assocPath(
            ["disease_results", "active_severeness"],
            active_severeness,
          ),
          assocPath(
            ["disease_results", "in_past_severeness"],
            in_past_severeness,
          ),
          over(
            lensPath(["disease_results", "active_disease_severeness"]),
            pipe(values, map(pipe(over(lensProp("results"), values))))
          ),

          over(
            lensPath(["disease_results", "in_past_disease_severeness"]),
            pipe(values, map(pipe(over(lensProp("results"), values))))
          )
        )(response);
      },
    };
  }

  getDiseaseList() {
    return {
      success(response) {
        return values(response);
      },
    };
  }
}
