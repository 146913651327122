import { values } from "ramda";

export default class UserTransform {
  getUserList() {
    return {
      success(response) {
        return values(response);
      },
    };
  }
}
