import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import DiseaseReducer from './disease';
import AuthReducer from './auth';
import UserReducer from './user';
import ClassReducer from './class';
import FilterReducer from './filter';
import {LOG_OUT_SUCCESS} from "./user/actions/logout";

const appReducer = combineReducers({
  form: reduxFormReducer,
  AuthReducer,
  DiseaseReducer,
  UserReducer,
  ClassReducer,
  FilterReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT_SUCCESS) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
