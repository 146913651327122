import React, {useMemo} from "react";
import classes from "./filter-items.module.scss";
import classNames from "classnames";
import TextParagraph from "../../../components/text-paragraph";
import {sort} from "ramda";
import {sortFunc} from "../../../helpers/common";

const FilterItems = ({
  isLoading,
  position,
  currentFilterItems,
  handleChangeFilterItems,
}) => {
  const filterList = useMemo(() => {
    return sort(sortFunc, currentFilterItems);
  }, [currentFilterItems]);

  const onChangeFilter = (filter) => {
    const filters = currentFilterItems.map((item) => {
      if (item.id === filter.id) {
        item.is_active = Number(!item.is_active);
      }
      return item;
    });

    if (!isLoading) {
      handleChangeFilterItems(position, filters);
    }
  };

  return (
    <div className={classes.FilterWrap}>
      {filterList.map((filter) => (
        <div
          onClick={() => onChangeFilter(filter)}
          className={classNames(classes.FilterBox, {
            [classes.IsActiveFilter]: filter.is_active,
            [classes.IsProcess]: isLoading,
          })}
        >
          <TextParagraph
            value={filter.name}
            isTruncate={true}
            rowShows={3}
            isShowToolTip={true}
          />
        </div>
      ))}
    </div>
  );
};

export default FilterItems;
