import React from "react";
import classes from "./disease-list.module.scss";
import { SIGNIFICANCES } from "../../../constants/disease";
import { Col, Divider, Row } from "antd";
import { useSelector } from "react-redux";
import { selectCurrentDiseaseClasses } from "../../../reducers/disease/selectors";
import { Link } from "react-router-dom";
import TextParagraph from "../../../components/text-paragraph";

const DiseaseList = () => {
  const data = useSelector(selectCurrentDiseaseClasses);
  return (
    <div className={classes.DiseaseListWrap}>
      {data.map((disease_class, index) => (
        <div key={index}>
          <div className={classes.DiseaseWrap}>
            <div className={classes.DiseaseItem}>
              {disease_class.diseases && disease_class.diseases.length ? (
                disease_class.diseases.map((item) => (
                  <Link to={`/disease/${item.disease_id}`} key={item.disease_id}>
                    <div
                      className={classes.FilterBox}
                      style={{
                        borderColor: SIGNIFICANCES[item.significance].color,
                        background: SIGNIFICANCES[item.significance].background,
                      }}
                    >
                      <TextParagraph
                        value= {item.disease_name}
                        isTruncate={true}
                        rowShows={3}
                        isShowToolTip={true}
                      />
                    </div>
                  </Link>
                ))
              ) : (
                <div className={classes.NoData}>No Data</div>
              )}
            </div>

            <div className={classes.DiseaseClassName}>{disease_class.name}</div>
          </div>
          <Divider />
        </div>
      ))}
    </div>
  );
};

export default DiseaseList;
