import { values } from "ramda";

export default class ClassTransform {
  getClassList() {
    return {
      success(response) {
        return values(response);
      },
    };
  }
}
