import SweetAlert from "../services/sweetalert";

export const useSweetAlert = () => {

  const progress = (text) =>
    new SweetAlert({
      title: `${text}...`,
      text: "",
      icon: false,
      showCancelButton: false,
      confirmText: "OK",
      progressBar: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

  const confirm = (text) =>
    new SweetAlert({
      title: `${text}!`,
      text: "",
      icon: "success",
      showCancelButton: false,
      confirmText:"OK",
    });

  const success = (text) =>
    new SweetAlert({
      title: `${text}!`,
      text: "",
      icon: "success",
      showCancelButton: false,
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true
    });

  const error = (text) =>
    new SweetAlert({
      title: `${text}!`,
      text: "",
      icon: "error",
      showCancelButton: false,
      confirmText: "OK",
      progressBar: false,
    });

  return { progress, confirm, success, error };
};
