import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserList } from "../../../reducers/user/actions/get-user-list";
import { showErrorFromServerError } from "../../../helpers/request";
import { useSweetAlert } from "../../../helpers/useSweetAlert";
import classes from "./user.module.scss";
import UserList from "./user-list";
import MainButton from "../../../components/main-button";
import { UserAddOutlined } from "@ant-design/icons";
import { MODALS } from "../../../constants/user";
import SaveUserModal from "./save-user-modal";
import { insertUser } from "../../../reducers/user/actions/insert-user";
import {deleteUser} from "../../../reducers/user/actions/delete-user";

const User = () => {
  const alert = useSweetAlert();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(null);

  const getListUser = () => {
    setIsLoading(true);
    dispatch(getUserList())
      .catch(showErrorFromServerError(alert))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSaveUser = (data) => {
    alert.progress("Adding").fire();
    dispatch(insertUser(data))
      .then(() => {
        alert.progress().close();
        alert.success("Added Successfully!").fire().then(() => {
          setModal(null);
        });
      })
      .catch(showErrorFromServerError(alert));
  };

  const handleDelete = (data) => {
    alert.progress("Deleting").fire();
    dispatch(deleteUser(data.user_id))
      .then(() => {
        alert.progress().close();
        alert
          .success("Deleted Successfully!")
          .fire()
          .then(() => {
            setModal(null);
          });
      })
      .catch(showErrorFromServerError(alert));
  }

  useEffect(() => {
    getListUser();
  }, []);

  return (
    <div className={classes.Container}>
      <div className={classes.ActionWrap}>
        <MainButton
          className={classes.NewUserButton}
          onClick={() => setModal(MODALS.saveUserModal)}
        >
          <UserAddOutlined /> Add User
        </MainButton>
      </div>
      <UserList isLoading={isLoading} onDelete={handleDelete} />

      {modal === MODALS.saveUserModal && (
        <SaveUserModal
          onSubmit={onSaveUser}
          visible={modal === MODALS.saveUserModal}
          onHide={() => setModal(null)}
          user={null}
        />
      )}
    </div>
  );
};

export default User;
