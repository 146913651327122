import React from "react";
import classes from "./forgot-password-successfully.module.scss";
import { connect } from "react-redux";
import { Card } from "antd";
import { LOGO } from "../../../../constants/images";

function ForgotPasswordSuccessfully() {
  return (
    <div className={classes.RegisterPasswordSuccessfully}>
      <Card className={classes.RegisterPasswordSuccessfullyWrap} bodyStyle={{ width: "100%" }}>
        <div className={classes.Form}>
          <div className={classes.LogoImage}>
            <img src={LOGO} />
          </div>
          <div className={classes.SuccessText}>
            Please check your email to set new password!
          </div>
        </div>
      </Card>
    </div>
  );
}
const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, null)(ForgotPasswordSuccessfully);
