import ObjectService from "../../../services/object.service";
import { getFilterList } from "./get-filter-list";

export const DELETE_FILTER_FETCH = "DELETE_FILTER_FETCH";
export const DELETE_FILTER_SUCCESS = "DELETE_FILTER_SUCCESS";
export const DELETE_FILTER_FAILED = "DELETE_FILTER_FAILED";

const objectService = new ObjectService();

export const deleteFilter = (id) => {
  return (dispatch) => {
    dispatch(deleteFilterFetch());
    return objectService
      .deleteObject({ filter_id: id })
      .then(() => {
        dispatch(deleteFilterSuccess());
        dispatch(getFilterList());
      })
      .catch((error) => {
        dispatch(deleteFilterFailed(error));
        return Promise.reject(error);
      });
  };
};

export const deleteFilterFetch = () => ({
  type: DELETE_FILTER_FETCH,
});

export const deleteFilterSuccess = (payload) => ({
  payload,
  type: DELETE_FILTER_SUCCESS,
});

export const deleteFilterFailed = (list) => ({
  list,
  type: DELETE_FILTER_FAILED,
});
