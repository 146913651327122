import AuthService from "../../../services/auth.service";

export const FORGOT_PASSWORD_FETCH = "FORGOT_PASSWORD_FETCH";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

const authService = new AuthService();

export const forgotPassword = (data) => {
  return (dispatch) => {
    dispatch(ForgotPasswordFetch());
    return authService
      .forgotPassword(data)
      .then(() => {
        dispatch(ForgotPasswordSuccess());
      })
      .catch((error) => {
        dispatch(ForgotPasswordFailed());
        return Promise.reject(error);
      });
  };
};

export const ForgotPasswordFetch = (user) => ({
  user,
  type: FORGOT_PASSWORD_FETCH,
});

export const ForgotPasswordSuccess = (user) => ({
  user,
  type: FORGOT_PASSWORD_SUCCESS,
});

export const ForgotPasswordFailed = (user) => ({
  user,
  type: FORGOT_PASSWORD_FAILED,
});
