import ObjectService from "../../../services/object.service";
import { getClassList } from "./get-class-list";

export const DELETE_CLASS_FETCH = "DELETE_CLASS_FETCH";
export const DELETE_CLASS_SUCCESS = "DELETE_CLASS_SUCCESS";
export const DELETE_CLASS_FAILED = "DELETE_CLASS_FAILED";

const objectService = new ObjectService();

export const deleteClass = (id) => {
  return (dispatch) => {
    dispatch(deleteClassFetch());
    return objectService
      .deleteObject({ class_id: id })
      .then(() => {
        dispatch(deleteClassSuccess());
        dispatch(getClassList());
      })
      .catch((error) => {
        dispatch(deleteClassFailed(error));
        return Promise.reject(error);
      });
  };
};

export const deleteClassFetch = () => ({
  type: DELETE_CLASS_FETCH,
});

export const deleteClassSuccess = (payload) => ({
  payload,
  type: DELETE_CLASS_SUCCESS,
});

export const deleteClassFailed = (list) => ({
  list,
  type: DELETE_CLASS_FAILED,
});
