import React from "react";
import { useController } from "react-hook-form";
import classes from "./radio-box-group.module.scss";
import classNames from "classnames";

import { Radio } from "antd";

const RadioBoxGroup = ({
  control,
  name,
  defaultValue,
  className,
  options,
  children,
  ...props
}) => {
  const { field } = useController({
    control,
    name,
    defaultValue,
  });

  return (
    <Radio.Group
      className={classNames(classes.SelectInput, className)}
      {...field}
      {...props}
    >
      {options.map((item) => (
        <Radio value={item.value}>{item.label}</Radio>
      ))}
    </Radio.Group>
  );
};

export default RadioBoxGroup;
