import React from "react";
import { Modal } from "antd";
import MainButton from "../../../../components/main-button";
import classes from "./save-filter-modal.module.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "../../../../components/form-items/input";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  always,
  applySpec,
  defaultTo,
  either,
  mergeLeft,
  pipe,
  prop,
} from "ramda";
import withLoadData from "./with-load-data";
import RadioBoxGroup from "../../../../components/form-items/radio-box-group";
import { FILTER_POSITION } from "../../../../constants/filter";

const schema = yup.object().shape({
  name: yup.string().required(),
});

const SaveFilterModal = ({
  visible,
  onSubmit,
  onHide,
  filterItem,
  defaultValues,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const OPTIONS_DATA = [
    { value: FILTER_POSITION.vertical, label: "vertical" },
    { value: FILTER_POSITION.horizontal, label: "horizontal" },
  ];

  return (
    <Modal
      title={filterItem.id ? "Edit Filter" : "Create Filter"}
      open={visible}
      onCancel={onHide}
      footer={null}
      className={classes.FormModal}
      width={500}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.Row}>
          <label className={classes.Label}>Name:</label>
          <Input control={control} name="name" />
        </div>

        <div className={classes.Row}>
          <label className={classes.Label}>Position:</label>
          <RadioBoxGroup
            options={OPTIONS_DATA}
            control={control}
            name="horizontal_vertical"
            className={classes.PositionWrap}
          />
        </div>

        <div className={classes.Footer}>
          <MainButton type="submit" disabled={isSubmitting || !isDirty}>
            Save
          </MainButton>
        </div>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state, props) => {
  const defaultValues = applySpec({
    id: either(prop("id"), always(null)),
    name: either(prop("name"), always("")),
    horizontal_vertical: either(
      prop("horizontal_vertical"),
      always(FILTER_POSITION.horizontal)
    ),
  })(props.filterItem);

  return {
    defaultValues: pipe(
      defaultTo({}),
      mergeLeft(defaultValues)
    )(props.filterItem),
  };
};

export default compose(
  withLoadData,
  connect(mapStateToProps, undefined)
)(SaveFilterModal);
