import axios from "axios";
import { serialize } from 'object-to-formdata';

export default class HttpService {
  get(url, data) {
    return this.send(url, data, "get");
  }

  post(url, data, formDataOptions = {}) {
    const formData = serialize(data, {
      allowEmptyArrays: true,
      ...formDataOptions,
    });
    return this.send(url, formData, "post", {
      headers: { "Content-Type": "multipart/form-data;" },
    });
  }

  send(url, data, method, properties) {
    return new Promise((resolve, reject) => {
      axios(url, {
        data,
        method,
        withCredentials: true,
        ...properties,
      })
        .then(({ data }) => resolve(data))
        .catch((error) => {
          reject(error);
        });
    });
  }
}
