import React, { useMemo } from "react";
import { Table, Space, Popconfirm, Tooltip } from "antd";
import { useSelector } from "react-redux";
import {
  DeleteOutlined,
  EditOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import classes from "./disease-list.module.scss";
import { sort } from "ramda";
import { selectCurrentDiseasesByClass } from "../../../../reducers/disease/selectors";
import { ADMINISTRATION_SUB_PAGES } from "../../../../constants/pages";
import { Link } from "react-router-dom";
import { sortFunc } from "../../../../helpers/common";
import { TOOLTIP_BACKGROUND_COLOR } from "../../../../constants/common";

const DiseaseList = ({
  onEdit,
  onDelete,
  handleSwitch,
  isLoading,
  classId,
}) => {
  const data = useSelector(selectCurrentDiseasesByClass)(classId);

  const onSwitch = (item, index) => {
    const upperItem = diseaseList[index - 1];
    handleSwitch(item.disease_id, upperItem.disease_id);
  };

  const diseaseList = useMemo(() => {
    return sort(sortFunc, data);
  }, [data]);

  const columns = [
    {
      title: "Name",
      key: "class_name",
      render: (_, record, index) => (
        <Space size="middle" className={classes.NameWrap}>
          <Link
            to={{
              pathname: `${ADMINISTRATION_SUB_PAGES.DISEASE_MANAGEMENT.path}/${record.disease_id}`,
              state: { classId: classId },
            }}
          >
            <div className={classes.Text}>{record.disease_name}</div>
          </Link>
          {index !== 0 && (
            <div className={classes.SwapIconWrap}>
              <Tooltip
                placement="top"
                title={"Move up"}
                color={TOOLTIP_BACKGROUND_COLOR}
              >
                <UpCircleOutlined
                  className={classes.SwapIcon}
                  onClick={() => onSwitch(record, index)}
                />
              </Tooltip>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "150px",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            placement="bottom"
            title="Are you sure to delete this disease?"
            onConfirm={() => onDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined className={classes.DeleteIcon} />
          </Popconfirm>

          <EditOutlined
            className={classes.EditIconIcon}
            onClick={() => onEdit(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        loading={isLoading}
        bordered
        columns={columns}
        dataSource={diseaseList}
        className={classes.ListItems}
        scroll={{ x: 800, y: "calc(100vh - 250px)" }}
        pagination={false}
      />
    </div>
  );
};

export default DiseaseList;
