import UserService from "../../../services/user.service";
import {getUserList} from "./get-user-list";

export const INSERT_USER_FETCH = "INSERT_USER_FETCH";
export const INSERT_USER_SUCCESS = "INSERT_USER_SUCCESS";
export const INSERT_USER_FAILED = "INSERT_USER_FAILED";

const userService = new UserService();

export const insertUser = (data) => {
  return (dispatch) => {
    dispatch(insertUserFetch());
    return userService
      .insertUser({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email
      })
      .then(() => {
        dispatch(insertUserSuccess());
        dispatch(getUserList());
      })
      .catch((error) => {
        dispatch(insertUserFailed(error));
        return Promise.reject(error);
      });
  };
};

export const insertUserFetch = () => ({
  type: INSERT_USER_FETCH,
});

export const insertUserSuccess = (payload) => ({
  payload,
  type: INSERT_USER_SUCCESS,
});

export const insertUserFailed = (list) => ({
  list,
  type: INSERT_USER_FAILED,
});
