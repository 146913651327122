import ObjectService from "../../../services/object.service";
import { getDiseaseListByClass } from "./get-disease-list-by-class";

export const SORT_SWITCH_DISEASE_FETCH = "SORT_SWITCH_DISEASE_FETCH";
export const SORT_SWITCH_DISEASE_SUCCESS = "SORT_SWITCH_DISEASE_SUCCESS";
export const SORT_SWITCH_DISEASE_FAILED = "SORT_SWITCH_DISEASE_FAILED";

const objectService = new ObjectService();

export const sortSwitchDisease = (id, upperId, classId) => {
  return (dispatch) => {
    dispatch(sortSwitchDiseaseFetch());
    return objectService
      .sortSwitchObject({ disease_id: id, upper_id: upperId })
      .then(() => {
        dispatch(sortSwitchDiseaseSuccess());
        dispatch(getDiseaseListByClass(classId));
      })
      .catch((error) => {
        dispatch(sortSwitchDiseaseFailed(error));
        return Promise.reject(error);
      });
  };
};

export const sortSwitchDiseaseFetch = () => ({
  type: SORT_SWITCH_DISEASE_FETCH,
});

export const sortSwitchDiseaseSuccess = (payload) => ({
  payload,
  type: SORT_SWITCH_DISEASE_SUCCESS,
});

export const sortSwitchDiseaseFailed = (list) => ({
  list,
  type: SORT_SWITCH_DISEASE_FAILED,
});
