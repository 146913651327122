import {curry, pathOr} from "ramda";

export const showErrorFromServerError = curry((alert, error) => {
  window.err = error

  const message = pathOr(
    "Uncaught error, please try again later",
    ["Error"],
    error
  );

  alert.progress().close();
  alert.error(message).fire();
});
