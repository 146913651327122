import DiseaseService from "../../../services/disease.service";
import { getDiseaseListByClass } from "./get-disease-list-by-class";
import {propOr} from "ramda";

export const INSERT_DISEASE_FETCH = "INSERT_DISEASE_FETCH";
export const INSERT_DISEASE_SUCCESS = "INSERT_DISEASE_SUCCESS";
export const INSERT_DISEASE_FAILED = "INSERT_DISEASE_FAILED";

const diseaseService = new DiseaseService();

export const insertDisease = (data) => {
  return (dispatch) => {
    dispatch(insertDiseaseFetch());
    return diseaseService
      .insertDisease({
        name: data.name,
        class_id: data.class_id,
        stages: data.stages
      })
      .then((res) => {
        dispatch(insertDiseaseSuccess());
        dispatch(getDiseaseListByClass(data.class_id));
        return propOr(null, 'new_id', res);
      })
      .catch((error) => {
        dispatch(insertDiseaseFailed(error));
        return Promise.reject(error);
      });
  };
};

export const insertDiseaseFetch = () => ({
  type: INSERT_DISEASE_FETCH,
});

export const insertDiseaseSuccess = (payload) => ({
  payload,
  type: INSERT_DISEASE_SUCCESS,
});

export const insertDiseaseFailed = (list) => ({
  list,
  type: INSERT_DISEASE_FAILED,
});
