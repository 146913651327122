import React from "react";
import { useController } from "react-hook-form";
import classes from "./textarea.module.scss";
import classNames from "classnames";
import { Input } from "antd";

const { TextArea } = Input;

const Textarea = ({
  control,
  name,
  defaultValue,
  className,
  children,
  rows = 4,
  ...props
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
  });

  const error = fieldState.error;

  return (
    <TextArea
      rows={rows}
      status={error ? "error" : ""}
      className={classNames(classes.Textarea, className)}
      {...field}
      {...props}
    />
  );
};

export default Textarea;
