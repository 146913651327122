import React, { useEffect, useState } from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import classes from "./disease-selection-box.module.scss";
import {Popover, Select, Spin} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentDiseasesByClass } from "../../../../../../reducers/disease/selectors";
import { selectClasses } from "../../../../../../reducers/class/selectors";
import { getClassList } from "../../../../../../reducers/class/actions/get-class-list";
import { getDiseaseListByClass } from "../../../../../../reducers/disease/actions/get-disease-list-by-class";
import { Link } from "react-router-dom";
import { ADMINISTRATION_SUB_PAGES } from "../../../../../../constants/pages";

const { Option } = Select;

const content = (classItems, diseases, currentClass, onSelectClass, onHide, isLoading) => {
  return (
    <div className={classes.Container}>
      <div className={classes.HeaderWrap}>
        <Select
          showSearch
          className={classes.ClassSelection}
          value={currentClass}
          onSelect={onSelectClass}
        >
          {classItems.map((item) => (
            <Option value={item.class_id}>{item.class_name}</Option>
          ))}
        </Select>
      </div>

      {isLoading ? (
        <div className={classes.SpinWrap}>
          <Spin size="medium" />
        </div>
      ) : (
        <div className={classes.DiseaseWrap}>
          {!diseases.length && (
            <div className={classes.NoData}>
              No Data
            </div>
          )}
          {diseases.map((item) => (
            <Link
              to={`${ADMINISTRATION_SUB_PAGES.DISEASE_MANAGEMENT.path}/${item.disease_id}`}
              onClick={() => onHide(false)}
            >
              <div className={classes.DiseaseItem}>{item.disease_name}</div>
            </Link>
          ))}
        </div>
      )}


    </div>
  );
};

const DiseaseSelectionBox = ({ classIdDefault }) => {
  const dispatch = useDispatch();
  const classItems = useSelector(selectClasses);
  const [currentClass, setCurrentClass] = useState(classIdDefault);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const diseases = useSelector(selectCurrentDiseasesByClass)(currentClass);

  const onSelectClass = (data) => {
    setCurrentClass(data);
  };

  const onHide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (value) => {
    setVisible(value);
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(getClassList()).finally(() => {
      setIsLoading(false);
    })
  }, []);

  useEffect(() => {
    if (!currentClass && classItems.length) {
      setCurrentClass(classItems[0].class_id);
    }
  }, [currentClass, classItems]);

  useEffect(() => {
    if (currentClass) {
      setIsLoading(true);
      dispatch(getDiseaseListByClass(currentClass)).finally(() => {
        setIsLoading(false);
      })
    }
  }, [currentClass]);

  return (
    <div className={classes.PopoverContainer}>
      <Popover
        content={() =>
          content(classItems, diseases, currentClass, onSelectClass, onHide, isLoading)
        }
        trigger="click"
        placement="bottom"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <CaretDownOutlined  className={classes.Icon}/>
      </Popover>
    </div>
  );
};

export default DiseaseSelectionBox;
