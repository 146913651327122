import React from "react";
import classes from "./main-button.module.scss";
import classNames from "classnames";

const MainButton = ({ type, disabled, children, className, ...props }) => {
  return (
    <button
      type={type}
      className={classNames(classes.Button, className, {[classes.Disabled]: disabled})}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default MainButton;
