import DiseaseService from "../../../services/disease.service";
import {getDiseaseDetail} from "./get-disease-detail";

export const CLONE_ACTIVE_DISEASE_FETCH = "CLONE_ACTIVE_DISEASE_FETCH";
export const CLONE_ACTIVE_DISEASE_SUCCESS = "CLONE_ACTIVE_DISEASE_SUCCESS";
export const CLONE_ACTIVE_DISEASE_FAILED = "CLONE_ACTIVE_DISEASE_FAILED";

const diseaseService = new DiseaseService();

export const cloneActiveDisease = (data) => {
  return (dispatch) => {
    dispatch(cloneActiveDiseaseFetch());
    return diseaseService
      .cloneActiveDisease({
        disease_id: data.disease_id,
        active_disease: data.active_disease,
      })
      .then(() => {
        dispatch(cloneActiveDiseaseSuccess());
        dispatch(getDiseaseDetail(data.disease_id));
      })
      .catch((error) => {
        dispatch(cloneActiveDiseaseFailed(error));
        return Promise.reject(error);
      });
  };
};

export const cloneActiveDiseaseFetch = () => ({
  type: CLONE_ACTIVE_DISEASE_FETCH,
});

export const cloneActiveDiseaseSuccess = (payload) => ({
  payload,
  type: CLONE_ACTIVE_DISEASE_SUCCESS,
});

export const cloneActiveDiseaseFailed = (list) => ({
  list,
  type: CLONE_ACTIVE_DISEASE_FAILED,
});
