import React from "react";
import classes from "./login-form.module.scss";
import { connect } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Input from "../../../../components/form-items/input";
import { Card } from "antd";
import { LOGO } from "../../../../constants/images";
import MainButton from "../../../../components/main-button";
import { Link } from "react-router-dom";
import { PAGES } from "../../../../constants/pages";

const schema = yup.object().shape({
  username: yup.string().email("Invalid email format").required(""),
  password: yup.string().required(),
});

function LoginForm({ onSubmit, isShowForgotLink }) {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting, isDirty, errors },
  } = useForm({ resolver: yupResolver(schema) });

  const watchUsername = watch("username");

  return (
    <div className={classes.LoginForm}>
      <Card className={classes.LoginFormWrap} bodyStyle={{ width: "100%" }}>
        <div className={classes.Form}>
          <div className={classes.LogoImage}>
            <img src={LOGO} />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.Row}>
              <Input
                className={classes.InputCustom}
                control={control}
                name="username"
                placeholder="Email"
              />
            </div>
            <div className={classes.Row}>
              <Input
                className={classes.InputCustom}
                control={control}
                name="password"
                type="password"
                placeholder="Password"
              />
            </div>
            <div className={classes.Error}>{errors.username?.message}</div>
            {isShowForgotLink && watchUsername && !errors.username && (
              <div className={classes.ForgotPasswordLink}>
                <Link
                  to={{
                    pathname: PAGES.FORGOT_PASSWORD.path,
                    state: {email: watchUsername},
                  }}
                >
                  Forgot password?
                </Link>
              </div>
            )}
            <div className={classes.ButtonWrap}>
              <MainButton
                className={classes.ButtonCustom}
                type="submit"
                disabled={isSubmitting || !isDirty}
              >
                Login
              </MainButton>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
}
const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, null)(LoginForm);
