import HttpService from "./http.service";
import { BACK_END_URL } from "../environments";

export default class ObjectService {
  constructor() {
    this.http = new HttpService();
  }

  deleteObject(data) {
    return this.http.post(`${BACK_END_URL}/delete.php`, data);
  }

  sortSwitchObject(data) {
    return this.http.post(`${BACK_END_URL}/sort_switch.php`, data);
  }
}
