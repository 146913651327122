import React from "react";
import { Route, Redirect } from "react-router-dom";
import {PAGES} from "../../constants/pages";


const RouteWrapper = ({ component: Component, restricted, user, ...rest }) => {
   const userExist = user && user.id;
  return (
    <Route
      {...rest}
      render={(props) =>
        restricted || (!restricted && userExist) ? (
          <Component {...props} />
        ) : userExist ? (
            <Redirect to={PAGES.DISEASE.path} />
        ) : (
          <Redirect to={PAGES.LOGIN.path} />
        )
      }
    />
  );
};

export default RouteWrapper;
