import React from "react";
import classes from "./filter-item.module.scss";
import { FormOutlined, AppstoreAddOutlined } from "@ant-design/icons";
import classNames from "classnames";

const FilterItem = ({
  title,
  score,
  description,
  isDefault,
  color,
  bgColor,
  onItemClick,
}) => {
  return (
    <div
      className={classNames(classes.ContentItem, {
        [classes.IsDefault]: !isDefault,
      })}
      style={{
        borderColor: color,
        background: bgColor,
      }}
    >
      <div className={classes.EditIconWrap} onClick={onItemClick}>
        {isDefault ? (
          <FormOutlined className={classes.EditIcon} />
        ) : (
          <AppstoreAddOutlined className={classes.EditIcon} />
        )}
      </div>
      <div className={classes.ContentItemWrap}>
        <div className={classes.TextWrap}>
          <div className={classes.Title}>{title}</div>
          <div className={classes.Description}>{description}</div>
        </div>
        <div
          className={classes.Score}
          style={{
            color: color,
          }}
        >
          {score}
        </div>
      </div>
    </div>
  );
};

export default FilterItem;
