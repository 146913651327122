import React, { useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import classes from "./text-paragraph.module.scss";
import Truncate from "react-truncate";
import { Tooltip } from "antd";

function TextParagraph({
  value,
  className,
  rowShows = 1,
  isTruncate = false,
  isShowToggle = false,
  handleToggle = () => {},
  isShowToolTip = false,
  tooltipPosition = "top",
  ...anyProps
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [truncated, setTruncated] = useState(false);

  const handleTruncate = (data) => {
    if (truncated !== data) {
      setTruncated(data);
    }
  };

  const onToggle = () => {
    const data = !isExpanded;
    handleToggle();
    setIsExpanded(data);
  };

  return (
    <div className={classNames(className, classes.TextParagraph)} {...anyProps}>
      {isTruncate &&
        !isExpanded &&
        (isShowToolTip ? (
          <Tooltip placement={tooltipPosition} title={value}>
            <Truncate
              className={classNames(classes.Message, {
                [classes.Visible]: truncated,
              })}
              lines={rowShows}
              ellipsis={
                <span>
                  ...
                  {isShowToggle && (
                    <span
                      className={classes.MoreLessLink}
                      onClick={() => onToggle()}
                    >
                      more
                    </span>
                  )}
                </span>
              }
              onTruncate={handleTruncate}
              children={value}
            />
          </Tooltip>
        ) : (
          <Truncate
            className={classNames(classes.Message, {
              [classes.Visible]: truncated,
            })}
            lines={rowShows}
            ellipsis={
              <span>
                ...
                {isShowToggle && (
                  <span
                    className={classes.MoreLessLink}
                    onClick={() => onToggle()}
                  >
                    more
                  </span>
                )}
              </span>
            }
            onTruncate={handleTruncate}
            children={value}
          />
        ))}

      {isExpanded && <span className={classes.MessageText}>{value}</span>}

      {!isTruncate && <span className={classes.MessageText}>{value}</span>}

      {isTruncate && isExpanded && isShowToggle && (
        <span className={classes.MoreLessLink} onClick={() => onToggle()}>
          less
        </span>
      )}
    </div>
  );
}

const mapStateToProps = () => {};

export default connect(mapStateToProps, null)(TextParagraph);
