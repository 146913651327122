import DiseaseService from "../../../services/disease.service";
import {getDiseaseDetail} from "./get-disease-detail";

export const EDIT_SIGNIFICANCE_FETCH = "EDIT_SIGNIFICANCE_FETCH";
export const EDIT_SIGNIFICANCE_SUCCESS = "EDIT_SIGNIFICANCE_SUCCESS";
export const EDIT_SIGNIFICANCE_FAILED = "EDIT_SIGNIFICANCE_FAILED";

const diseaseService = new DiseaseService();

export const editSignificance = (data) => {
  return (dispatch) => {
    dispatch(editSignificanceFetch());
    return diseaseService
      .updateSignificance({
        id: data.id,
        score: data.score,
        description: data.description
      })
      .then(() => {
        dispatch(editSignificanceSuccess());
        dispatch(getDiseaseDetail(data.disease_id));
      })
      .catch((error) => {
        dispatch(editSignificanceFailed(error));
        return Promise.reject(error);
      });
  };
};

export const editSignificanceFetch = () => ({
  type: EDIT_SIGNIFICANCE_FETCH,
});

export const editSignificanceSuccess = (payload) => ({
  payload,
  type: EDIT_SIGNIFICANCE_SUCCESS,
});

export const editSignificanceFailed = (list) => ({
  list,
  type: EDIT_SIGNIFICANCE_FAILED,
});
