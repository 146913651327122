import HttpService from "./http.service";
import { BACK_END_URL } from "../environments";
import ClassTransform from "./class.transform";

export default class ClassService {
  constructor() {
    this.http = new HttpService();
    this.transform = new ClassTransform();
  }

  getClassList() {
    return this.http
      .get(`${BACK_END_URL}/class_list.php`)
      .then((res) => this.transform.getClassList().success(res));
  }

  insertClass(data) {
    return this.http.post(`${BACK_END_URL}/insert_class.php`, data);
  }

  updateClass(data) {
    return this.http.post(`${BACK_END_URL}/update_class.php`, data);
  }
}
