import React from "react";
import { useController } from "react-hook-form";
import classes from "./select.module.scss";
import classNames from "classnames";
import { Select } from "antd";

const { Option } = Select;

const SelectInput = ({
  control,
  name,
  defaultValue,
  className,
  options,
  valueKey,
  nameKey,
  children,
  ...props
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
  });

  const error = fieldState.error;

  return (
    <Select
      status={error ? "error" : ""}
      className={classNames(classes.SelectInput, className)}
      {...field}
      {...props}
    >
      {options.map((item) => (
        <Option value={item[valueKey]} key={item[valueKey]}>
          {item[nameKey]}
        </Option>
      ))}
    </Select>
  );
};

export default SelectInput;
