import React from "react";
import { useController } from "react-hook-form";
import classes from "./input.module.scss";
import classNames from "classnames";
import { Input as AntInput } from "antd";

const Input = ({
  control,
  name,
  defaultValue,
  type = "text",
  className,
  children,
  ...props
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
  });

  const error = fieldState.error;

  return (
    <AntInput
      {...field}
      {...props}
      type={type}
      status={error ? "error" : ""}
      className={classNames(classes.Input, className)}
    />
  );
};

export default Input;
