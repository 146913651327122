import React, { useEffect } from "react";
import { Modal, Spin } from "antd";
import MainButton from "../../../../components/main-button";
import classes from "./save-disease-modal.module.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "../../../../components/form-items/input";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import {
  always,
  applySpec,
  defaultTo,
  either,
  mergeLeft,
  pathOr,
  pipe,
  prop,
  propOr,
  values,
} from "ramda";
import SelectInput from "../../../../components/form-items/select";
import { selectClasses } from "../../../../reducers/class/selectors";
import withLoadData from "./with-load-data";
import CheckboxGroup from "../../../../components/form-items/checkbox-group";
import { SEVERENESS } from "../../../../constants/disease";
import { selectDiseaseDetails } from "../../../../reducers/disease/selectors";

const schema = yup.object().shape({
  name: yup.string().required(),
  class_id: yup.string().required(),
  stages: yup.array().min(1, "You need to choose at least one severeness!"),
});

const SaveDiseaseModal = ({
  visible,
  onSubmit,
  onHide,
  diseaseItem,
  defaultValues,
  isLoading,
}) => {
  const classesList = useSelector(selectClasses);
  const diseaseItemDetail = useSelector(selectDiseaseDetails)(
    pathOr(null, ["disease_id"], diseaseItem)
  );

  const options = values(SEVERENESS).map((item) => {
    return { value: Number(item.value), label: item.name };
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty, errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    if (diseaseItemDetail) {
      reset({
        ...defaultValues,
        stages: propOr([], "severeness_stages", diseaseItemDetail),
      });
    }
  }, [diseaseItemDetail]);

  return (
    <Modal
      title={diseaseItem ? "Edit Disease" : "Create Disease"}
      visible={visible}
      onCancel={onHide}
      footer={null}
      className={classes.FormModal}
      width={500}
    >
      {isLoading && (
        <div className={classes.SpinWrap}>
          <Spin size="medium" />
        </div>
      )}
      {!isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.Row}>
            <label className={classes.Label}>Name:</label>
            <Input control={control} name="name" />
          </div>
          <div className={classes.Row}>
            <label className={classes.Label}>Class:</label>
            <SelectInput
              control={control}
              name="class_id"
              options={classesList}
              valueKey="class_id"
              nameKey="class_name"
              className={classes.SelectInput}
            />
          </div>

          <div className={classes.Row}>
            <label className={classes.Label}>Severeness:</label>
            <CheckboxGroup
              control={control}
              name="stages"
              options={options}
              className={classes.SelectInput}
            />
            <div className={classes.Error}>{errors.stages?.message}</div>
          </div>
          <div className={classes.Footer}>
            <MainButton type="submit" disabled={isSubmitting || !isDirty}>
              Save
            </MainButton>
          </div>
        </form>
      )}
    </Modal>
  );
};

const mapStateToProps = (state, props) => {
  const defaultValues = applySpec({
    id: either(prop("disease_id"), always(null)),
    name: either(prop("disease_name"), always("")),
    stages: either(prop("stages"), always([])),
  })(props.diseaseItem);
  defaultValues.class_id = props.currentClass;

  return {
    defaultValues: pipe(
      defaultTo({}),
      mergeLeft(defaultValues)
    )(props.diseaseItem),
  };
};

export default compose(
  withLoadData,
  connect(mapStateToProps, undefined)
)(SaveDiseaseModal);
