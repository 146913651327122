import React, { useEffect } from "react";
import classes from "./forgot-password-form.module.scss";
import { connect } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Card } from "antd";
import { LOGO } from "../../../../constants/images";
import MainButton from "../../../../components/main-button";
import { Link } from "react-router-dom";
import { PAGES } from "../../../../constants/pages";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email format").required(""),
});

function ForgotPasswordForm({ onSubmit, email }) {
  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting, isDirty },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    setValue("email", email, { shouldDirty: true, shouldValidate: true });
  }, [email]);

  return (
    <div className={classes.RegisterPasswordForm}>
      <Card
        className={classes.RegisterPasswordFormWrap}
        bodyStyle={{ width: "100%" }}
      >
        <div className={classes.Form}>
          <div className={classes.LogoImage}>
            <img src={LOGO} />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.Text}>
              Login credentials of
              <span className={classes.EmailName}> {email}</span> will be reset.{" "}
              <br />
              Click 'Send' to proceed.
            </div>
            <div className={classes.ButtonWrap}>
              <MainButton
                className={classes.ButtonCustom}
                type="submit"
                disabled={isSubmitting || !isDirty}
              >
                Send
              </MainButton>
            </div>
            <div className={classes.LoginLink}>
              <Link to={PAGES.LOGIN.path}>Login</Link>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
}
const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, null)(ForgotPasswordForm);
