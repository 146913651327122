import FilterService from "../../../services/filter.service";
import * as FilterNormalize from "../normalize";

export const GET_FILTER_LIST_FETCH = "GET_FILTER_LIST_FETCH";
export const GET_FILTER_LIST_SUCCESS = "GET_FILTER_LIST_SUCCESS";
export const GET_FILTER_LIST_FAILED = "GET_FILTER_LIST_FAILED";

const filterService = new FilterService();

export const getFilterList = () => {
  return (dispatch) => {
    dispatch(getFilterListFetch());
    return filterService
      .getFilterList()
      .then((resultList) => {
        const normalized = FilterNormalize.getFilterList.success(resultList);
        dispatch(getFilterListSuccess(normalized));

        return resultList;
      })
      .catch((error) => {
        dispatch(getFilterListFailed(error));
        return Promise.reject(error);
      });
  };
};

export const getFilterListFetch = () => ({
  type: GET_FILTER_LIST_FETCH,
});

export const getFilterListSuccess = (payload) => ({
  payload,
  type: GET_FILTER_LIST_SUCCESS,
});

export const getFilterListFailed = (list) => ({
  list,
  type: GET_FILTER_LIST_FAILED,
});
