import { normalize } from "normalizr";
import {disease} from "./schema";

export const getFilteringDisease = {
  success: (response) => {
    return normalize(response, [disease]);
  },
};

export const getDiseaseList = {
  success: (response) => {
    return normalize(response, [disease]);
  },
};
